





































































































@import '../../../shared/assets/scss/core/_breakpoints.scss';
.card-text {
  text-transform: capitalize;
}
.media-thumbnails {
  font-size: 1rem;
  background-color: var(--color-grey-base);
  color: var(--color-primary-darker);
}

.search-result-item .card-body {
  @include breakpoint-sm-down() {
    --card-body-flex-direction: column;
  }
}

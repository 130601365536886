










.aula-card-variables {
  --thumbnail-position: static;
  --thumbnail-height: 200px;
  --thumbnail-width: auto;
  --thumbnail-background-color: var(--color-grey-base);
  --description-height: 70px;
  --description-width: auto;
}
.aula-card {
  border-radius: 5px;
  overflow: hidden;
  transition: box-shadow ease-out 200ms;

  &:hover {
    box-shadow: var(--box-shadow-base);
  }
  &[aria-selected='true'] .card-thumbnail > * {
    opacity: var(--selected-media-card-opacity);
  }

  .card-thumbnail {
    position: var(--thumbnail-position);
    height: var(--thumbnail-height);
    width: var(--thumbnail-width);
    background-color: var(--thumbnail-background-color);
    overflow: hidden;
    transition: height 200ms var(--ease-in-out);
  }
  .card-description {
    height: var(--description-height);
    width: var(--description-width);
    background-color: white;
    transition: height 200ms var(--ease-in-out);
  }
}































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/core/scrollbar.scss';

.inbox-menu-container {
  min-width: 200px;
  max-width: 400px;
  flex-shrink: 0;

  @include breakpoint-lg-down() {
    min-width: 100%;
    max-width: unset;
  }
}

.subscription-holder {
  --resizer-color: var(--color-grey-base);

  position: relative;
  max-width: 100%;
  @include breakpoint-lg() {
    min-width: var(--subscription-min-width, unset);

    &.wide {
      flex-basis: 40%;
    }
  }

  &[data-view='standard'] {
    --subscription-min-width: var(--subscription-min-width-standard);
  }

  &[data-view='compact'] {
    --subscription-min-width: var(--subscription-min-width-compact);
  }
}

.loading-spinner {
  width: 40px;
  height: 40px;
  display: flex;
  margin: auto;
}

.reminder-alerts {
  padding: 15px 20px;
}

.messages-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: calc(100vh - var(--menu-top-bar-height));

  @include breakpoint-lg-down() {
    height: calc(100svh - var(--menu-top-bar-height));
  }

  &[data-dragging] {
    /deep/ .message-inbox:not([data-selected]) {
      border-radius: 4px;

      .inbox-button {
        color: var(--color-grey);

        &:hover {
          outline: none;
          cursor: no-drop;
        }
      }
    }

    /deep/ .inbox-button:hover {
      background-color: var(--color-grey-dark);
      outline: 1px solid var(--color-primary-dark);
      cursor: alias;
    }
  }

  .threads-container {
    flex-grow: 1;
    flex-basis: 0;
    margin-bottom: auto;
    overflow-y: auto;
  }

  /deep/ .resizer {
    --resizer-z-index: 999;
  }

  .new-message {
    background-color: $color-grey-base;
    padding-left: 1rem;
    padding-right: 1rem;
    max-height: 50vh;

    &[data-active] {
      overflow-y: auto;
    }
  }
}

.messages-container {
  .aula-modal-open & {
    /deep/ .subscription-holder,
    /deep/ .b-nav-dropdown a {
      z-index: initial !important;
    }
  }
}

.messages-overview {
  display: flex;
  flex-grow: 1;
  flex-wrap: nowrap;
  min-height: 0;
  background-color: var(--color-grey-light);

  @include breakpoint-lg-down() {
    overflow: hidden;
  }

  .conversation-holder {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: var(--conversation-min-width);
    width: 0;

    @include breakpoint-lg-down {
      position: fixed;
      inset: 0;
      z-index: var(--modal-z-index);
      background: #f6f7f8;
      width: auto;
      min-width: auto;
    }
  }
}

.message-toolbar-container {
  @include breakpoint-lg() {
    display: block !important;
  }
}

.mobile-navigation-container {
  height: 60px;
  min-height: 60px;
  background-color: var(--color-primary-darker);

  @include breakpoint-lg() {
    display: none;
  }

  .title {
    color: var(--color-white);
    width: 100%;
    height: 60px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile-back-button {
    display: flex;
    position: fixed;
    left: 0;
    top: 0;
    width: 50px;
    z-index: 1037;

    .icon-Aula_arrow_new_item {
      color: $color-white;
      font-weight: 900;
      font-size: 28px;
      display: inline-block;
      line-height: 60px;
      vertical-align: top;
      float: left;
      margin: 0 -48px 0 0px;
      padding: 0px 21px;

      -webkit-transform: rotate(270deg);
      -moz-transform: rotate(270deg);
      -o-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
      transform: rotate(270deg);
    }
  }
}

.slidedown-enter-active,
.slidedown-leave-active,
.slidedown-enter,
.slidedown-leave-to,
.slidedown-enter-active > div,
.slidedown-leave-active > div {
  transition: 0.5s;
}

.slidedown-enter,
.slidedown-leave-to {
  > div {
    top: -115px;
  }
}

.no-choice {
  width: 100%;
  min-height: 200px;
  text-align: center;
  position: relative;
  color: $color-grey;
  font-size: 24px;
  top: calc(50vh - 140px);

  .icon {
    font-size: 40px;
  }
}

.no-invitations-text {
  display: flex;
  justify-content: center;
  font-weight: bold;
  text-align: center;
}

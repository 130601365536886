.infobox {
  width: 506px;
  background-color: $color-white;
  box-shadow: 0 1px 4px 0 rgba(166, 166, 166, 0.5);

  .infobox-header {
    height: 60px;
    width: 506px;
    background-color: $color-primary-base;
    margin-top: 19px;

    .infobox-header-text {
      height: 22px;
      color: $color-white;
      font-family: Lato;
      font-size: 18px;
      font-weight: bold;
      line-height: 22px;
      margin-left: 24px;
      padding: 19px;
    }
  }

  .infobox-container {
    margin-top: 5%;
    margin-left: 5%;
    padding: 30px;
  }

  .infobox-text {
    color: $color-primary-darker;
  }

  .infobox-button {
    background: $color-primary-light;
    border: 0;
    color: $color-white;
    border-radius: 4px;
    font-size: 16px;
    height: 50px;
    bottom: 10%;
    width: 100%;

    &:hover {
      background: $color-primary-base;
      color: $color-white;
    }

    &:disabled {
      background: $color-help-text;
    }

    .infobox-button-text {
      color: $color-white;
      font-size: 16px;
      font-weight: bold;
    }
  }
}

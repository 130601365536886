




















































































.more-menu-item {
  --aula-badge-box-shadow-color: var(--color-grey-dark);
  &:hover {
    --aula-badge-box-shadow-color: var(--color-primary-darker);
  }
}


























































































.text-container {
  width: calc(100% - 45px);
  /deep/ .user {
    position: absolute;
    right: 0px;
    top: 22px;
    .shortname {
      margin-top: 8px;
    }
  }
}

.text-container-self {
  /deep/ .user {
    position: absolute;
    left: 0px;
    top: 22px;
    .shortname {
      margin-top: 8px;
    }
  }
}































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/contacts/_contacts.scss';

.contact-avatar {
  --width: 30px;
  --height: 30px;
  --font-size: 10px;
}





























































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.forwarded-self /deep/ {
  .message {
    .sender-name {
      visibility: visible;
    }
    .message-body {
      background-color: $color-grey-base !important;
      color: var(--color-primary-darker) !important;
      &:after {
        border-left: 10px solid $color-grey-base !important;
      }
    }
  }
}

.forwarded /deep/ {
  .message {
    .sender-name {
      visibility: visible;
    }
    .message-body {
      background-color: $color-grey-base !important;
      color: var(--color-primary-darker) !important;
      &:after {
        border-right: 10px solid $color-grey-base !important;
      }
    }
  }
}

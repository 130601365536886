























.advance-search-input {
  input {
    border: unset;
  }
}
















































































































































































































































































































































































































































































































































































































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/elements/infobox.scss';
@import '../../../shared/assets/scss/core/animations.scss';

.btn-icon-submit {
  --font-size: 1.25rem;

  margin-right: 4px;
}

.sensitive-msg-container {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;

  .custom-checkbox {
    margin-bottom: 0;
  }
}

.placeholder-text {
  margin-top: 5px;
  margin-bottom: 15px;
}

.message-area {
  min-height: 72px;
  justify-content: center;

  .leave-timestamp {
    font-size: 0.7rem;
    display: block;
  }
}

.button-send {
  max-width: 130px;
  height: 42px;
  min-width: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  white-space: nowrap;
  padding: 0 16px;
  &:hover {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.send-option-dropdown {
  max-width: 130px;
  height: 42px;
  width: 42px;

  /deep/.dropdown-toggle {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /deep/.send-options {
    margin-bottom: -42px;
    min-width: 250px;

    @include breakpoint-lg-down() {
      margin-right: 26px;
    }

    .b-dropdown-text {
      padding: 0.25rem 0.5rem;
      color: var(--color-primary-dark);
    }
    .close-icon {
      color: var(--color-primary-dark);
    }
    .dropdown-item {
      padding: 1rem 3rem 1rem 1rem;
    }
  }
}
.send-option {
  display: grid;
  grid-template-columns: 35px 1fr;
  .answer-option-label {
    text-transform: uppercase;
    font-weight: bold;
  }
  .answer-option-receiver {
    color: var(--color-grey);
    @include breakpoint-lg-down() {
      white-space: normal;
    }
  }
}
.send-button-icon {
  --font-size: 0.5rem;
}
.answer-icon {
  --font-size: 1.5rem;
}
.answer-all-icon {
  --font-size: 1.75rem;
}

.message-textarea {
  &.active {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  h5 {
    font-size: 14px;
    font-weight: normal;
  }
  .btn-link {
    padding: 14px 44px;
  }
}

.message-textarea {
  position: unset;
  .message-textarea-editor {
    border-color: transparent;
    background-color: transparent !important;
    border-radius: 5px;
    outline: 0;
    padding: 0;
    min-height: 48px;
    margin-bottom: 10px;
    .theme-employee & {
      color: $color-primary-darker-employee;
    }
    .theme-guardian & {
      color: $color-primary-darker-guardian;
    }
    .theme-child & {
      color: $color-primary-darker-child;
    }
    .theme-admin & {
      color: $color-primary-darker-admin;
    }
    /deep/ > .mce-container {
      height: 0;
      overflow: hidden;
    }
  }
  /deep/ .mce-top-part,
  /deep/ .mce-statusbar {
    display: none;
  }
  &.disabled {
    /deep/ .mce-edit-area {
      display: none;
    }
  }

  /deep/ .aula_editor .tox.tox-tinymce {
    @include breakpoint-lg-down() {
      min-height: auto;
    }
  }
}

.footer {
  margin-top: 0;
  margin-bottom: 8px;
}

.message-textarea.active {
  .message-textarea-editor {
    background-color: $color-input-focus;
    /deep/ > .mce-container {
      height: auto;
    }
  }
  /deep/ .mce-edit-area iframe {
    min-height: 125px !important;
  }
  /deep/ .mce-top-part,
  /deep/ .mce-statusbar {
    display: block;
  }
}

.prefixIcon {
  position: relative;
  width: 100%;
  background: $color-white;
  border-radius: 5px;
  margin: 15px 0;
  cursor: pointer;
  height: 42px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.preIcon {
  padding: 0 12px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  gap: 8px;
  align-items: center;
  height: 100%;
}

.message-area /deep/ footer {
  padding-bottom: 0;
}

.help-tooltip {
  /deep/.tooltip-inner.right {
    height: 210px;
    .tooltip-body {
      padding-bottom: 50px;
    }
  }
}

.message-utility-container {
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  min-height: 25px;
}

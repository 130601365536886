
























































.thread-standard-avatars {
  --avatar-size: 36px;
  --overlapping-size: 14px;

  display: flex;
  width: 58px;
  flex-wrap: wrap;

  &[data-small] {
    --avatar-size: 28px;
    --overlapping-size: 12px;

    width: 44px;
    > .thread-avatar {
      --font-size: 10px;
    }

    &:not([data-extra='0']) > .thread-avatar:last-child::after {
      font-size: 11px;
    }

    &[data-count='1'] {
      --avatar-size: 40px;
      > .thread-avatar {
        --font-size: 14px;
      }
    }
    > .thread-avatar:last-child::after {
      font-size: 12px;
    }
  }

  &[data-count='1'] {
    --avatar-size: 44px;

    > .thread-avatar {
      --font-size: 16px;

      margin: auto;
    }
  }

  &[data-count='2'] {
    > .thread-avatar {
      &:last-child {
        margin-top: calc(-1 * var(--overlapping-size) - 4px);
        margin-left: auto;
        z-index: 2;
      }
    }
  }

  &[data-count='3'] {
    > .thread-avatar {
      flex-basis: 50%;

      &:not(:last-child) {
        margin-right: calc(-1 * var(--overlapping-size));
      }
      &:first-child {
        z-index: 1;
      }
      &:last-child {
        margin-top: calc(-1 * var(--overlapping-size));
        margin-left: auto;
        margin-right: auto;
        z-index: 2;
      }
    }
  }

  &:not([data-extra='0']) {
    --extra-count: attr(data-extra);
    > .thread-avatar:last-child {
      background-color: var(--color-grey-dunkel);
      /deep/ > * {
        visibility: hidden;
      }
      &::after {
        content: '+' var(--extra-count);
        position: absolute;
        inset: 0;
        background-color: var(--color-grey-dunkel);
        color: var(--color-primary-darker);
        display: grid;
        place-items: center;
        font-size: 14px;
      }
    }
  }

  > .thread-avatar {
    --width: var(--avatar-size);
    --height: var(--avatar-size);
    --font-size: 12px;

    box-shadow: 0 0 0 1px var(--color-white);
    outline: 2px solid var(--color-white);
    font-weight: normal;
  }
}





















































































































































.address-cell {
  width: 200px;
  max-width: 200px;
  min-width: 200px;
}





















































































































































































































































































































































































































































































































































































































































































































@import '../../../shared/assets/scss/core/variables';
@import '../../../shared/assets/scss/core/breakpoints';
@import '../../../shared/assets/scss/components/presence/presence-employee';
.activity-data {
  &.vacation {
    .user-name {
      @include breakpoint-lg-down() {
        padding: 10px !important;
      }
    }
  }
  .user-icon {
    @include breakpoint-lg-down() {
      width: unset !important;
    }
  }
}
.icon-Aula_forward-arrow {
  font-size: 0.8rem;
  line-height: unset;
}

.contact-master-data {
  background-color: var(--color-grey-light);

  .mobile-group {
    background-color: var(--color-white);
    border-bottom: 1px solid var(--color-grey-base);
    padding: 25px 10px;
    font-size: 15px;
    cursor: pointer;

    &.profile {
      font-weight: 900;
    }

    .icon {
      margin-top: 5px;
      color: var(--color-grey-dark);
    }
  }

  .nav {
    flex-wrap: nowrap;
    white-space: nowrap;

    @include breakpoint-lg() {
      flex-wrap: wrap;
      overflow-x: unset;
    }
  }

  .nav-link {
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    position: relative;
    cursor: pointer;
    padding: 10px 15px;
    border-radius: 0;
    color: var(--color-primary-base);

    @include breakpoint-lg-down() {
      font-weight: normal;
      font-size: 15px;
    }

    &.active::after {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 8px 0 8px;
      border-color: hotpink transparent transparent transparent;
      left: calc(50% - 8px);
      bottom: -9px;
      z-index: 1;
    }
  }

  .contacts-group-filter {
    position: relative;
    background-color: var(--color-grey-base);
    height: 60px;
    padding: 5px;

    &:focus {
      box-shadow: none;
    }

    .institution-selector {
      width: 100%;
    }

    .group-filter {
      width: 450px;

      &:first-child {
        margin-left: 0;
      }
    }

    .sort-mobile {
      width: 42%;
      margin: 0;
    }

    .search-user-group {
      @include breakpoint-lg-down() {
        padding-top: 0;
        margin-left: -5px;
        width: 55%;
        border-right: 1px solid var(--color-grey-base);
        border-radius: 0;
      }
    }

    .master-data-selection {
      width: 100%;

      @include breakpoint-lg-down() {
        margin-top: 0;
      }
    }

    .loading-master-data {
      width: 80px;
      height: 80px;
      position: absolute;
      left: 10px;
      top: -10px;
    }
  }

  .prd-filter-mobile {
    padding: 20px;
  }

  .prd-header-mobile {
    top: 80px;
    left: 20px;

    .prd-sorter {
      width: 100%;
      background-color: var(--color-white);
      position: fixed;
      top: 58px;
      padding: 15px;
      border-bottom: 1px solid var(--color-grey-base);

      /deep/ .dropdown-menu {
        max-width: 200px !important;
        width: 150px;
      }
    }
  }

  .contacts-container {
    @include breakpoint-lg-down() {
      background-color: var(--color-white);
    }

    .config-sections {
      border-width: 0;
      border-color: var(--color-primary-darker);
      border-bottom-width: 2px;
      border-style: solid;
      margin-bottom: 20px;
      position: relative;

      @include breakpoint-lg-down() {
        border-bottom: 0;
        text-align: right;
        margin-top: 20px;
        margin-bottom: 0;
        margin-right: 10px;
      }

      .nav-item {
        color: var(--color-primary-base);

        .active,
        &:hover {
          color: var(--color-primary-darker);
        }

        .active::after {
          border-color: var(--color-primary-darker) transparent transparent transparent;
        }
      }

      .right {
        margin-left: auto;
      }

      .custom-control {
        margin-bottom: 0;
        padding-top: 3px;
      }

      .sorter {
        @include breakpoint-sm-up() {
          padding-top: 3px;
        }

        @include breakpoint-tablet-portrait() {
          padding-top: 3px;
        }
      }

      .sorter /deep/ .dropdown-menu {
        max-width: 200px !important;
        width: 200px;
      }

      .view-mode {
        i {
          opacity: 0.5;
        }

        .active {
          opacity: 1;
        }
      }
    }
  }

  .contacts-body {
    width: 100%;

    .contact-header-mobile {
      @include breakpoint-lg-down() {
        background-color: var(--color-grey-base);
        width: 100%;
        padding: 10px 15px;
        position: relative;
        margin-left: 15px;
        margin-right: -15px;
        display: block;
        z-index: 1000;

        h1 {
          font-size: 20px;
          float: left;
          font-weight: normal;
        }

        .current-letter {
          float: right;
          font-weight: 900;
        }
      }
    }

    .contacts-group-filter {
      position: relative;
      bottom: 10px;
      width: 100%;
      height: 53px;
      z-index: 1036;
      background-color: var(--color-grey-base);
      padding-bottom: 60px;
      margin-left: 15px;

      .group-filter {
        position: relative;
        left: 10px;
        margin: 0;
        width: 55%;
        border: 1px solid var(--color-grey-base);
        border-radius: 0;
      }
    }

    @include breakpoint-lg-down() {
      height: 100%;
      padding-bottom: 70px;
      padding-left: 15px;
      overflow-x: auto;
      margin-left: -15px;
      background-color: var(--color-white);
      overflow-y: auto;
      width: 100%;
    }

    #contactList {
      .contact {
        background-color: var(--color-white);

        @include breakpoint-lg-down() {
          padding-right: 15px;
          margin-left: 15px;
        }

        .main-contact {
          padding: 20px;
          border-bottom: 1px var(--color-grey-base) solid;

          @include breakpoint-lg-down() {
            padding: 30px 15px;
            width: 40%;
          }

          @include breakpoint-lg-down() {
            padding: 12px 20px 12px 0;
            width: 100%;
          }
        }

        .relations {
          padding-left: 20px;

          @include breakpoint-lg-down() {
            padding-left: 15px;
            width: 100%;
          }

          .relation {
            padding: 20px;

            @include breakpoint-lg-down() {
              padding: 30px 30px;
            }

            @include breakpoint-lg-down() {
              padding: 15px 0;
              border-bottom: 1px var(--color-grey-base) solid;
            }
          }
        }

        &.list-view {
          width: 100% !important;
          margin-left: 0;
          margin-bottom: 0;
          margin-top: 10px;
          display: flex;

          .main-contact {
            width: 30%;
            min-width: 250px;
            border-right: 1px var(--color-grey-base) solid;
            border-bottom: none;

            &.full {
              border-right: none;
              width: 100%;

              .contact-info {
                width: 100% !important;
              }
            }
          }

          .relations {
            width: 100%;

            @include breakpoint-lg-down() {
              width: 92%;
            }

            .relation {
              &.first {
                border-right: 1px var(--color-grey-base) solid;
              }

              .contact-profile {
                .contact-info {
                  width: 100% !important;

                  @include breakpoint-lg-down() {
                    width: 80% !important;
                  }
                }
              }
            }
          }

          .contact-profile {
            .contact-img {
              width: 70px;

              /deep/ .user-icon {
                width: 50px !important;
                height: 50px !important;

                .shortname {
                  width: 50px !important;
                  height: 50px !important;
                }
              }
            }

            .contact-info {
              width: 80%;

              .contact-item {
                margin-top: 0;
                margin-right: 0;

                .meta-name {
                  display: inline-block;
                }
              }

              .contact-meta {
                width: 49%;
              }

              &.relation-profile {
                width: 100%;
                padding: 30px 35px;
                display: flex;

                .profile-info {
                  width: 50%;

                  .contact-name {
                    font-weight: unset;
                  }
                }

                .contact-data {
                  margin-top: 25px;
                  width: 50%;
                }

                @include breakpoint-lg-down() {
                  display: block;

                  .profile-info {
                    width: 90%;
                  }

                  .contact-data {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }

      .no-results {
        padding: 20px;
      }
    }
  }

  .prd-title {
    padding-top: 30px;
    padding-left: 20px;
  }

  .prd-header-mobile {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $prd-header-mobile-z-index;
    width: 100%;

    .prd-view-mode-header {
      padding-top: 20px;
      padding-bottom: 20px;
      background-color: var(--color-primary-darker);
      color: var(--color-white);

      .text-key {
        font-weight: bold;
        font-size: 15px;
      }

      .right-text {
        font-size: 13px;
        text-transform: none;
      }
    }
  }

  .prd-body-mobile {
    min-height: calc(100vh - 50px);
    overflow: auto;
    position: absolute;
    top: 38px;
    z-index: 10;
    background-color: var(--color-grey-light);

    .prd-answer-mobile {
      width: 100vw;
      height: 100%;
      background-color: var(--color-white);
    }
  }

  .sorter,
  .filter-sorter {
    z-index: 1002;

    .icon-Aula_down-arrow {
      font-size: 5px;
      position: relative;
      top: -1px;
      margin-left: 4px;
    }

    .asc /deep/ a::before {
      font-family: 'aula', Lato, serif;
      content: '\e988';
      margin-right: 5px;
      margin-left: -10px;
      color: var(--color-primary-base);
    }

    .desc /deep/ a::before {
      font-family: 'aula', Lato, serif;
      content: '\e987';
      margin-right: 5px;
      margin-left: -10px;
      color: var(--color-primary-base);
    }
  }
}

.el-select-dropdown {
  .filtering-group-warning {
    font-weight: 900;
    padding: 20px;
  }
}

.personal-reference-data {
  width: 100%;

  @include breakpoint-lg-down() {
    background-color: var(--color-grey-light);
    padding-bottom: 100px;
  }

  .user-data {
    display: flex;
    flex-direction: row;
    margin-bottom: 2px;
    align-items: stretch;
    width: 100%;

    @include breakpoint-lg-down() {
      margin-top: 2px;
    }

    .user-icon {
      position: relative;
      margin-right: 2px;
      padding: 12px 10px 10px 20px;
      background-color: var(--color-grey-base);
      min-width: 90px;

      @include breakpoint-lg-down() {
        background-color: var(--color-white);
      }

      .mobile-name {
        margin-left: 60px;
        margin-top: -40px;
        width: calc(100% - 60px);
        word-break: break-word;
      }

      /deep/ .user-icon {
        width: 45px;
        height: 45px;

        .user-img {
          width: 47px;
          height: 47px;
        }
      }
    }

    .user-icon,
    .user-name.header {
      @include breakpoint-lg-down() {
        padding: 20px 10px;
        min-width: 210px;
        width: 50%;
      }
    }

    .user-name {
      font-weight: 900;
      width: 40%;
      margin-right: 2px;
      padding: 22px 20px;
      background-color: var(--color-white);

      @include breakpoint-lg-down() {
        padding: 20px 10px;
      }
    }

    .user-answer,
    .user-answer.header {
      width: 120px;

      @include breakpoint-lg-down() {
        min-width: 50px;
        padding-left: 8px;
      }
    }

    .user-answer {
      display: inline-flex;
      margin-right: 2px;
      padding: 22px 20px;
      background-color: var(--color-white);

      @include breakpoint-lg-down() {
        padding: 20px 10px;
      }

      &.full {
        width: 100%;
      }
    }

    .user-answer-question,
    .user-answer-question.header {
      width: 100%;
    }

    .user-answer-question {
      padding: 22px 20px;
      background-color: var(--color-white);

      @include breakpoint-lg-down() {
        padding: 20px 10px;
      }
    }

    .header {
      background-color: var(--color-grey-base);
      padding: 20px;
      font-weight: 900;
    }
  }
}














































































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/documents/_overview.scss';

.badge-notification.documents {
  --position: unset;
  margin-inline-end: 10px;
  float: left;

  /deep/ .aula-badge {
    width: 18px;
    height: 18px;

    i:before {
      content: '';
    }
  }
}

.table-cell.collapsed-only /deep/ .badge {
  position: relative;
  top: 9px;
  left: 30px;
}

.upload-link {
  cursor: pointer;
  float: left;
  margin-top: 12px;
}

.submit-row {
  margin-top: 15px;
}

.modalSharedWith {
  .row {
    .col,
    .col-1,
    .col-2,
    .col-4 {
      padding: 10px 10px 5px 10px;
      border-bottom: solid 1px #eee;
    }

    .text {
      padding-top: 13px;
    }

    .check {
      text-align: center;

      label {
        margin-right: 0;
      }
    }

    .delete {
      text-align: right;
      padding-top: 14px;
    }
  }
}

.file-badge {
  position: absolute;
  left: -6px;
  top: -8px;
}

.aula-documentsCommonList-container {
  .breadcrumbs {
    cursor: pointer;
  }

  .fit-content {
    width: fit-content !important;
  }

  .spinHolder {
    clear: both;
  }

  .show-more {
    padding: 20px 0;
    text-align: center;

    button {
      float: none;
    }
  }
}

.sort {
  margin-top: auto;

  &.asc::before,
  &.desc::before {
    margin-left: 3px !important;
    margin-right: -3px !important;
    color: unset !important;
  }
}

































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.onboarding-step1 {
  .accept-container {
    @include breakpoint-lg-down() {
      width: 100%;
      background: $color-primary-base;
      position: fixed;
      left: 0;
      bottom: 0;
      padding: 15px 20px;
      color: $color-white;
    }
  }
  /deep/ a {
    font-weight: bold;
  }
  height: 100%;
  @include breakpoint-lg-down() {
    height: auto;
  }
  .privacy-policy-changes {
    background: $color-grey-base;
    padding: 10px;
    margin-bottom: 20px;
  }
  .step1-title {
    font-size: 36px;
    font-weight: 900;
    line-height: 40px;
    margin-bottom: 30px;
    @include breakpoint-lg-down() {
      font-size: 24px;
    }
  }
  .step1-content {
    overflow-x: auto;
    height: 95%;
    margin-bottom: 20px;
    padding-right: 20px;
    @include breakpoint-lg-down() {
      height: 90%;
    }
    @include breakpoint-lg-down() {
      height: auto;
    }
  }
  .scrollbar {
    @include breakpoint-lg-down() {
      overflow-y: hidden;
    }
  }
  a {
    .theme-employee & {
      color: $color-primary-dark-employee;
    }
    .theme-guardian & {
      color: $color-primary-dark-guardian;
    }
    .theme-child & {
      color: $color-primary-dark-child;
    }
    .theme-admin & {
      color: $color-primary-dark-admin;
    }

    text-decoration: underline;
  }
  &.has-warning {
    .step1-content {
      height: 87% !important;
      @include breakpoint-lg-down() {
        height: 82% !important;
      }
    }
    .accept-container {
      @include breakpoint-lg-down() {
        padding-left: 30px !important;
        padding-top: 10px;
      }
      .warning {
        margin-bottom: 5px;
        padding-bottom: 0px;
        margin-left: -2px;
      }
    }
  }
}









































































































































































































































































.user-profile {
  flex-grow: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  .close-button {
    align-self: flex-end;
    height: 32px;
    width: 32px;
    border-radius: 4px;
    display: grid;
    place-items: center;

    .close-icon {
      --font-size: 20px;
    }
  }

  .user-information {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .main-avatar {
      --width: 150px;
      --height: 150px;
      --font-size: 2.5rem;
    }

    .avatar-container {
      position: relative;
      width: min-content;

      .edit-avatar-button {
        position: absolute;
        bottom: 0;
        right: 8px;
      }
    }
  }

  .setting-options {
    flex-grow: 1;
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    width: 100%;

    .option {
      position: relative;
      padding: 12px;
      display: grid;
      grid-template-columns: 24px 1fr;
      gap: 16px;
      border-radius: 8px;
      transition: background-color 150ms ease-in;

      &:hover {
        background-color: var(--color-white);

        .setting-icon {
          transform: scale(110%);
        }
        &::after {
          display: none;
        }
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 8px;
        left: 8px;
        height: 1px;
        background-color: var(--color-grey-dark);
      }

      .setting-icon {
        --font-size: 1.5rem;
        transition: transform 150ms ease-in;
        text-align: center;
      }
    }
  }
}
.footer {
  display: flex;
  gap: 12px;
}

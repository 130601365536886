.background {
  background-color: #18638f;
  min-height: 100%;
  height: 100%;

  @include breakpoint-lg-down() {
    height: unset;
  }

  .aula-onboarding {
    width: 100%;
    height: 100%;
    background: linear-gradient(117.36deg, #45b7c1 0%, #18638f 100%, #02487a 100%, #192a79 100%, #45b7c1 100%);
  }
}

.aula-onboarding {
  @include breakpoint-lg-down() {
    padding-top: 25px;
    padding-bottom: 50px;
  }

  .container {
    @include breakpoint-lg-down() {
      max-width: 100vw;
    }
  }

  .header {
    padding-top: 27px;
    padding-bottom: 27px;
    display: flex;

    @include breakpoint-lg-down() {
      padding-top: 10px;
      position: fixed;
      height: 120px;
      background-color: $color-primary-base-employee;
      top: 0;
      left: 0;
      margin-left: 0;
      z-index: 101;
      width: 100%;
      box-shadow: 0 0 22px 0 rgba(51, 51, 51, 0.5);
      padding-bottom: 27px;
      &.privacy{
        height: 65px;
      }
    }

    @include breakpoint-sm-down-landscape() {
      height: 65px;
    }

    .go-back {
      position: fixed;
      left: 20px;
      top: 5px;

      @include breakpoint-sm-down-landscape() {
        right: 110px;
        left: unset;
      }
    }

    .aula-logo {
      @include breakpoint-lg-down() {
        display: block;
        position: relative;
        margin: 10px auto 0 auto;
      }

      @include breakpoint-sm-down-landscape() {
        text-align: left;
        left: 20px;
        position: fixed;
      }

      .logo {
        color: $color-white;
        font-size: 3em;

        @include breakpoint-lg-down() {
          font-size: 1.5em;
        }

        .institution {
          margin-top: -10px;
          font-size: 20px;
        }
      }
    }

    .next-link {
      color: $color-white;
      font-size: 14px;
      position: fixed;
      top: 20px;
      right: 20px;
    }

    .icon-Aula_arrow_new_item {
      color: $color-white;
      margin-left: 8px;
      font-weight: 900;
      font-size: 28px;
      display: inline-block;
      line-height: 57px;
      vertical-align: top;
      float: left;
      margin-top: 0;
      -webkit-transform: rotate(270deg);
      -moz-transform: rotate(270deg);
      -o-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
      transform: rotate(270deg);
    }

    &.noaccess {
      height: 80px;
    }

    &.stepupfailure {
      height: 80px;
    }
  }


  .onboarding-steps-mobile {
    width: 100%;
    position: fixed;
    top: 60px;
    text-align: center;

    @include breakpoint-sm-down-landscape() {
      display: block;
      position: unset;
    }
  }

  .steps {
    display: inline-flex;
    margin-left: -30px;
    @include breakpoint-lg-down() {
      margin-top: 5px;
    }
    li {
      cursor: pointer;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      display: block;
      text-align: center;
      font-size: 16px;
      padding-top: 7px;
      margin-right: 7px;

      @include breakpoint-lg-down() {
        height: 35px;
        width: 35px;
        padding-top: 5px;
        margin-right: 0;
      }

      @include breakpoint-lg-down() {
        height: 30px;
        width: 30px;
        padding-top: 3px;
        font-size: 14px;
        margin-right: 6px;
        margin-left: 6px;
      }

      &.active {
        border: 1px solid $color-primary-base-employee;
      }
    }
  }

  .onboarding {
    background: $color-white;
    box-shadow: var(--box-shadow-base);
    height: 75vh;
    overflow-y: auto;
    border-radius: 4px;

    @include breakpoint-lg-down() {
      width: 100%;
      margin: auto;
      height: auto;
      margin-top: 115px;
      &.privacy {
        margin-top: 55px;
      }
      &.page {
        margin-top: 80px;
      }
    }

    @include breakpoint-sm-down-landscape() {
      margin-top: 60px;
    }

    .onboarding-wrapper {
      height: 100%;
    }

    .onboarding-steps {
      position: relative;
      padding: 40px 20px 30px 30px;
      height: 100%;
      box-shadow: 0 2px 12px 0 rgba(166, 166, 166, 0.5);

      @include breakpoint-lg-down() {
        padding: 40px 10px 30px 20px;
      }

      .step-title {
        font-size: 18px;
        font-weight: bold;
        line-height: 22px;
        margin-top: 70px;
        @include breakpoint-lg-down() {
          margin-top: 40px;
        }
      }

      .step-desc {
        margin-top: 25px;
        font-size: 15px;
        line-height: 24px;
      }

      .step-button {
        position: absolute;
        width: calc(100% - 60px);
        margin-top: 40px;
        bottom: 20px;
        background-color: $color-white;

        .previous-link {
          height: 50px;
          border: 1px solid $color-grey-dunkel;
          padding: 13px 25px;

          @include breakpoint-xl-down() {
            padding: 8px 15px;
          }
        }

        .send-btn {
          float: right;
          height: 50px;
          padding: 14px 25px;
          margin-bottom: 3px;

          @include breakpoint-xl-down() {
            padding: 8px 15px;
          }
        }
      }
    }

    .onboard-content {
      padding: 60px 20px 60px 40px;
      height: 100%;

      @include breakpoint-lg-down() {
        padding: 30px 5px 30px 5px;
      }
    }

    &.noaccess {
      margin-top: 80px;
    }

    &.stepupfailure {
      margin-top: 80px;
    }
  }

  .step1-title {
    font-size: 36px;
    font-weight: 900;
    line-height: 40px;
    margin-bottom: 30px;
  }

  .page-content {
    padding: 60px;

    @include breakpoint-lg-down() {
      padding: 30px;
    }

    text-align: justify;

    .content {
      overflow-x: auto;
      padding-right: 30px;
      height: 100%;

      @include breakpoint-lg-down() {
        padding-right: 10px;
      }
    }
  }

  .step-content {
    overflow-x: auto;
    height: 90%;
    margin-bottom: 20px;
    padding-right: 20px;

    @include breakpoint-lg-down() {
      height: auto;
    }
  }

  .onboarding-step2 {
    height: 100%;

    @include breakpoint-lg-down() {
      height: auto !important;
    }

    .step2-content {
      overflow-x: hidden;
      height: 100%;
      margin-bottom: 20px;

      @include breakpoint-lg-down() {
        height: auto;
      }

      .portal-roles {
        font-size: 24px;
        margin-bottom: 10px;
        font-weight: 900;
        text-transform: capitalize;
      }
    }

    &.has-warning {
      .step2-content {
        height: 95%;

        @include breakpoint-lg-down() {
          height: 90%;
        }
      }

      .warning {
        margin-left: 5px;
        margin-bottom: 5px;
      }
    }

    .scrollbar {
      @include breakpoint-lg-down() {
        overflow-y: hidden;
      }
    }
  }

  .scrollbar {
    @include breakpoint-lg-down() {
      overflow-y: hidden;
    }
  }

  .onboarding-steps {
    .step-desc {
      height: calc(75% - 130px);
      overflow-x: auto;
    }
  }

  .onboard-content {
    .intro-step {
      .step-title {
        font-size: 24px;
        font-weight: 900;
      }

      .step-desc {
        margin-top: 25px;
        font-size: 15px;
        line-height: 24px;
      }
    }
  }

  .step-button {
    .aula-spinner {
      width: 55px;
      height: 55px;
      position: absolute;
      left:81%;
      transform: translate(-50%, -50%);
      top: 50%;
    }
  }

  .next-link {
    .aula-spinner {
      width: 50px;
      height: 50px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      top: 50%;
    }
  }
}

//Theme styling for adult
.theme-guardian {
  .aula-onboarding {
    .steps {
      li {
        @include breakpoint-lg-down() {
          color: $color-white;
          border: 1px solid $color-primary-light-guardian;
        }

        &.active {
          border: 1px solid $color-primary-dark-guardian;
          color: $color-primary-dark-guardian;

          @include breakpoint-lg-down() {
            border: 1px solid $color-white;
            background-color: $color-white;
            color: $color-primary-light-guardian;
          }
        }
      }
    }

    .onboarding {
      .onboarding-steps {
        .step-button {
          .previous-link {
            color: $color-primary-dark-guardian;
          }
        }
      }
    }
  }
}

//Theme for employee
.theme-employee {
  .aula-onboarding {
    .steps {
      span {
        @include breakpoint-lg-down() {
          color: $color-white;
          border: 1px solid $color-primary-light-employee;
        }

        &.active {
          border: 1px solid $color-primary-dark-employee;
          color: $color-primary-dark-employee;

          @include breakpoint-lg-down() {
            border: 1px solid $color-white;
            background-color: $color-white;
            color: $color-primary-light-employee;
          }
        }
      }
    }

    .onboarding {
      .onboarding-steps {
        .step-button {
          .previous-link {
            color: $color-primary-dark-employee;
          }
        }
      }
    }
  }
}

//Theme styling for child
.theme-child {
  .aula-onboarding {
    .steps {
      span {
        @include breakpoint-lg-down() {
          color: $color-white;
          border: 1px solid $color-primary-light-child;
        }

        &.active {
          border: 1px solid $color-primary-dark-child;
          color: $color-primary-dark-child;

          @include breakpoint-lg-down() {
            border: 1px solid $color-white;
            background-color: $color-white;
            color: $color-primary-light-child;
          }
        }
      }
    }

    .onboarding {
      .onboarding-steps {
        .step-button {
          .previous-link {
            color: $color-primary-dark-child;
          }
        }
      }
    }
  }
}

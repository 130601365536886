






































































































.media-card {
  --thumbnail-position: relative;
}
.video-thumbnail {
  width: 100%;
  height: 100%;
}
.aula-card:hover {
  .media-delete-button {
    display: grid;
  }
  .media-detail-button {
    display: block;
  }
}
.media-delete-button {
  --btn-link-color: var(--color-white);
  position: absolute;
  right: 15px;
  bottom: 10px;
  z-index: 1;
  height: 40px;
  width: 40px;
  border: 1px solid var(--color-white);
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  place-items: center;

  &:hover {
    --btn-link-color: var(--color-primary-darker);
    background-color: var(--color-white);
  }
}
.media-detail-button {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  padding: 5px 10px;
  border-radius: 35px;
  background-color: rgba(0, 0, 0, 0.5);
  color: var(--color-white);
  display: none;
}

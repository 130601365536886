















































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/documents/_overview.scss';

.show-more {
  padding: 20px 0;
  text-align: center;
  button {
    float: none;
    margin-left: 0;
  }
}
.document-title {
  flex-wrap: wrap;
}
.aula-document-history-container {
  .media-data-container {
    cursor: pointer;
    @include breakpoint-xl-down() {
      margin-top: 5px;
    }
    .album-media-data {
      background-color: $color-grey-light;
      flex: unset;
    }
  }
  @include breakpoint-lg-down() {
    padding: 15px;
    padding-bottom: 50px !important;
  }
  @include breakpoint-sm-down() {
    padding: 10px;
  }
  h1 {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 40px;
  }
  .breadcrumbs {
    cursor: pointer;
  }
  .breadcumb-spacer {
    padding: 0 10px;
    display: inline-block;
  }
  .document-history-title-header {
    background-color: $color-grey-dark;
    padding: 0;
    margin: 0;
    h5 {
      margin-bottom: 0;
      line-height: 60px;
    }
    .icon-Aula_back-arrow {
      font-size: 1.5em;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .icon-header {
      color: $color-bg-black;
      cursor: pointer;
      @include breakpoint-lg() {
        display: inline-block;
        padding: 20px 0px 15px 0;
        margin-right: -17px;
      }
    }
  }
  .table {
    border-collapse: collapse;
    display: table;
    width: 100%;
    font-size: 14px;
    .table-row {
      background: #fff;
      display: table-row;
      .not-collapsed {
        display: none !important;

        @include breakpoint-xl() {
          display: table-cell !important;
        }
      }
      .collapsed-only {
        display: block !important;

        @include breakpoint-xl() {
          display: none !important;
        }
      }
      &.header {
        @include breakpoint-xl-down() {
          display: none;
        }
        background-color: $color-grey-dark;
        .table-cell {
          display: table-cell;
          padding: 15px;
          border-right: solid 2px transparent;
          border-top: solid 2px $color-grey-light;
          border-bottom: solid 2px $color-grey-light;
          text-transform: uppercase;
          text-align: center;
        }
      }
      &.body {
        .table-cell {
          border: solid 2px $color-grey-light;
          display: table-cell;
          padding: 15px;
          &.icon {
            background-color: $color-grey-base;
            vertical-align: middle;
            text-align: center;
            padding-top: 20px;
          }
          b {
            font-weight: 900;
          }
          .icon-Aula_elipses {
            display: block;
            margin-bottom: 15px;
            cursor: pointer;
          }
          .icon-Aula_write {
            display: block;
            cursor: pointer;
          }
        }
        &:hover {
          .table-cell {
            background: $color-grey-lighter;
            &.icon {
              background: $color-grey-dunkel;
              .icons {
                .user {
                  background: $color-grey-dunkel;
                  border: solid 3px $color-grey-dunkel;
                }
              }
            }
            b {
              font-weight: 900;
            }
          }
        }
      }
    }
  }
}

.aula-document-history-container {
  .table {
    .table-row {
      &.header {
        .table-cell {
          &.icon {
            span {
              .theme-guardian & {
                background-color: $color-primary-light-guardian;
              }
              .theme-employee & {
                background-color: $color-primary-light-employee;
              }
              .theme-child & {
                background-color: $color-primary-light-child;
              }
            }
          }
        }
      }
    }
  }
}

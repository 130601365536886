










[data-sidebar='content'] {
  overflow: auto;
  min-height: 0;
  height: 100%;
}

.login-guide {
  color: $color-white;
  text-align: center;
  font-size: 15px;
  position: absolute;
  right: 365px;
  top: 20px;
  width: 166px;
  height: 190px;

  @include breakpoint-xl-down() {
    right: 60px;
    top: 10px;
  }

  @include breakpoint-lg-down() {
    right: -10px;
    top: -10px;
  }

  &:focus-visible {
    border: 1px solid $color-white;
  }

  .background {
    position: absolute;
    width: 166px;
    height: 130px;
    padding: 50px 40px;
    margin: 1em auto 50px;
    text-align: center;
    color: $color-white;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 159px / 127px;
    opacity: 0.2;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    z-index: 1000;

    &::after {
      content: "";
      position: absolute;
      bottom: -30px;
      right: 106px;
      border-width: 0 0 30px 20px;
      border-style: solid;
      border-color: transparent $color-picker-button;
      display: block;
      width: 0;
    }

    &::before {
      content: "";
      position: absolute;
      bottom: -30px;
      right: 75px;
      border-width: 0 0 30px 50px;
      border-style: solid;
      border-color: transparent rgba(0, 0, 0, 0.5);
      opacity: 0.6;
      display: block;
      width: 0;
    }

    @include breakpoint-lg-down() {
      width: 126px;
      height: 96px;
      margin-left: 20px;
      margin-top: 15px;

      &::before {
        right: 55px;
        bottom: -30px;
      }

      &::after {
        right: 85px;
        bottom: -30px;
      }
    }
  }

  .intro {
    color: $color-white;
    text-align: center;
    font-size: 15px;
    position: absolute;
    top: 55px;
    right: 35px;

    @include breakpoint-lg-down() {
      top: 40px;
    }
  }
}

.aula-logo {
  width: 100%;
  text-align: center;
  font-size: 4.5em;
  color: $color-white;
  padding-top: 215px;

  @include breakpoint-lg-down() {
    padding-top: 120px;
  }

  @include breakpoint-sm-down-landscape() {
    padding-top: 30px;
    font-size: 3.8em;
  }
}

#main .container{
    padding-bottom: 100px;
}

.square-box {
  cursor: pointer;
  position: relative;
  width: 98%;
  overflow: hidden;
  background: transparent;
  border: 1px solid $color-white;
  color: $color-white;
  font-family: Lato;
  font-size: 22px;
  font-weight: 700;
  line-height: 29px;

  &.uni-login {
    background-color: $color-primary-dark-guardian;
    border: none;
  }

  &:focus-visible {
    border: 1px solid $color-grey-dunkel;
  }

  @include breakpoint-xl-down() {
    font-size: 18px;
  }

  @include breakpoint-lg() {
    &::before {
      content: "";
      display: block;
      padding-top: 100%;
    }
  }

  @include breakpoint-lg-down() {
    display: block;
    margin-bottom: 20px;
    font-size: 1.2em;
    padding: 25px;
  }

  .content {
    position: absolute;
    top: 30px;
    left: 15px;
    color: $color-white;

    > div {
      display: flex;
      flex-direction: column;
      align-items: start;
      margin-bottom:-20px;

      .nemid {
        height: 14px;
        margin-top: 10px;
        margin-left: 0px;
      }

      .mit-id-logo {
        width: 200px;
        height: 100px;
        margin-left: -54px;
        margin-top: -30px;
        margin-bottom: -10px;
      }
    }

    .employee-only {
      font-size: 18px;
      font-weight: unset;
    }

    @include breakpoint-lg-down() {
      position: relative;
      top: unset;
      left: unset;
    }
  }

  .remember-checkbox {
    position: absolute;
    bottom: 0px;
    left: 15px;
    font-size: 15px;

    @include breakpoint-lg-down() {
      margin-top: 10px;
      position: relative;
      font-size: 18px;
      top: unset;
      left: unset;
    }

    /deep/ .text {
      position: relative;
      top: -3px;
      margin-left: 5px;
    }
  }

  i {
    right: 20px;
    bottom: 20px;
    position: absolute;
    font-size: 14px;

    @include breakpoint-lg-down() {
      position: relative;
      float: right;
    }
  }
}

.aula-login-back {
  position: absolute;
  top: 100px;
  left: 133px;

  @include breakpoint-lg-down() {
    top: 30px;
    left: 30px;
  }

  i {
    color: $color-white;
    cursor: pointer;
    font-size: 2em;
  }

  &:focus-visible {
    border: 1px solid $color-grey-light;
  }
}

.login-method {
  font-size: 2em;
  color: $color-white;
  text-align: center;
}
.employee-login-method {
  color: var(--color-white);
  text-align: center;
}
.remember {
  text-align: center;
  height: 30px;
  color: $color-white;
}

.icon-Logo {
  color: $color-white;
}

.login-option {
  text-transform: uppercase;
}

#main {
  position: relative;
  min-height: 100vh;
  background: linear-gradient(117.36deg, $color-primary-light 0%, $color-primary-dark-guardian 100%, #02487a 100%, #192a79 100%, $color-primary-light 100%);

  @include breakpoint-sm-down() {
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.footer-links {
  position: absolute;
  bottom: 15px;
  left: 0;
  width: 100%;
  text-align: center;

  a {
    color: $color-white;
    font-size: 18px;
    display: block;
    line-height: 2rem;

    @include breakpoint-lg() {
      display: inline;

      & + a{
        margin-left: 2rem;
      }
    }
  }
}



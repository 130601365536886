








































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.styleguide-icons-container {
  .row > * {
    text-align: center;
    padding-bottom: 30px;
  }
  [class^='icon-'] {
    font-size: 40px;
    color: $color-primary-dark;
    display: block;
    .theme-employee & {
      color: $color-primary-light-employee;
    }
    .theme-child & {
      color: $color-primary-light-child;
    }
    .theme-admin & {
      color: $color-primary-light-admin;
    }
    .theme-guardian & {
      color: $color-primary-light-guardian;
    }
  }
}

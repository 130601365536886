























































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.search-result-item {
  .profile-picture-container {
    background-color: var(--color-primary-dark);
    display: flex;
    width: 130px;
    align-items: center;
  }

  .profile-picture {
    --width: 100%;
    --height: 130px;
    --border-radius: unset;
  }
}
.card-text {
  text-transform: capitalize;
}

.contact-info-icon {
  background-color: $color-grey-base;
  text-align: center;
  border-radius: 50%;
  display: inline;
  &:hover {
    background-color: $color-white;
  }
  &.phone {
    padding: 8px 12px;
    font-size: 1.5em;
  }
  &.email {
    padding: 12px 10px;
    font-size: 1em;
  }
}

.conversation /deep/ {
  .conversation-content {
    .message {
      margin-bottom: 5px;
    }

    .message-body {
      margin: 5px 0;
      padding: 10px 20px 10px 20px;
      border-radius: 8px;
      font-size: 15px;
      position: relative;
      word-wrap: break-word;
      overflow-wrap: anywhere;

      img {
        max-width: 100%;
      }

      .message-content iframe {
        max-width: 100%;
      }

      &.system-message {
        &::after {
          border-left: none !important;
          border-right: none !important;
        }
      }

      &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        top: 12px;
      }

      p:last-child {
        margin-bottom: 0;
      }

      a {
        color: $color-white;
        text-decoration: underline;
      }
    }

    .sender-name,
    .message-meta {
      color: var(--color-help-text);
      font-size: 14px;
    }

    .user {
      .user-icon {
        margin-top: 15px;

        @include breakpoint-xs-down() {
          margin-top: 25px;
          width: 35px;
          height: 35px;
        }
      }
    }

    .message-from-me {
      .message-body {
        &::after {
          right: -10px;
        }
      }
      &.message-type-message {
        max-width: 100%;
        margin-left: 10%;
      }
    }

    .message-from-person {
      &.message-type-message {
        max-width: 100%;
        margin-right: 10%;
      }
      .message-sent-time {
        color: $color-help-text;
      }
      .message-body {
        background: $color-white;

        .theme-employee & {
          color: $color-primary-darker-employee;
        }

        .theme-guardian & {
          color: $color-primary-darker-guardian;
        }

        .theme-child & {
          color: $color-primary-darker-child;
        }

        .theme-admin & {
          color: $color-primary-darker-admin;
        }

        &::after {
          border-right: 10px solid $color-white;
          left: -10px;
        }

        a {
          .theme-employee & {
            color: $color-primary-dark-employee;
          }

          .theme-guardian & {
            color: $color-primary-dark-guardian;
          }

          .theme-child & {
            color: $color-primary-dark-child;
          }

          .theme-admin & {
            color: $color-primary-dark-admin;
          }
        }
      }

      .user {
        .user-icon {
          .theme-employee & {
            background: $color-primary-darker-employee;
          }

          .theme-guardian & {
            background: $color-primary-darker-guardian;
          }

          .theme-child & {
            background: $color-primary-darker-child;
          }

          .theme-admin & {
            background: $color-primary-darker-admin;
          }
        }
      }
    }

    .message-deleted,
    .recipient-removed,
    .recipient-added {
      .message-body {
        margin-top: 15px;
        color: $color-conversation-left !important;
        background: $color-grey-base !important;

        .user {
          .user-icon {
            background-color: $color-primary-darker;
            height: 35px;
            width: 35px;
            font-size: 12px;
            position: absolute;
            right: 10px;
            top: calc(50% - 35px);

            span {
              line-height: 35px !important;
              height: 35px;
              width: 35px;
            }
          }
        }

        .text-container-self {
          .user {
            .user-icon {
              left: 10px;
              right: unset;
            }
          }
        }

        &.system-message {
          border-left: none;
          border-right: none;
        }
      }
    }

    .recipient-removed,
    .recipient-added {
      margin: 12px auto;
      padding-left: 10px;

      @include breakpoint-sm-up() {
        padding-left: 40px;
      }

      .system-message {
        color: var(--color-help-text);
        display: flex;
        align-items: center;
        justify-content: start;

        .system-message-icon {
          font-size: 20px;
        }

        .system-message-text {
          font-size: 12px;
          line-height: 16px;
          margin-left: 8px;

          .other-recipients {
            margin-right: 2px;

            &:hover {
              border-bottom: 1px solid var(--color-help-text);
            }
          }
        }
      }
    }

    .icon-Aula_down-arrow,
    .icon-Aula_up-arrow {
      font-size: 5px;
      cursor: pointer;
      position: relative;
      bottom: 1px;
    }

    .autoreply-label {
      margin-right: 3px;
      color: var(--color-alert);
    }
  }
}

//Theme styling
.theme-guardian {
  .conversation /deep/ .message-from-me {
    .message-body {
      background: $color-primary-dark-guardian;
      color: $color-white;

      &::after {
        border-left: 10px solid $color-primary-dark-guardian;
      }
    }
  }
}

.theme-employee {
  .conversation /deep/ .message-from-me {
    .message-body {
      background: $color-primary-dark-employee;
      color: $color-white;

      &::after {
        border-left: 10px solid $color-primary-dark-employee;
      }
    }
  }
}

.theme-child {
  .conversation /deep/ .message-from-me {
    .message-body {
      background: $color-primary-dark-child;
      color: $color-white;

      &::after {
        border-left: 10px solid $color-primary-dark-child;
      }
    }
  }
}

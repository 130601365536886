












































.sort-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
  text-transform: inherit;
}

.visibility-hidden {
  visibility: hidden;
}

.sort-icon {
  margin-left: 5px;
  margin-right: 5px;
}

.asc-desc {
  opacity: 0.5;
}

@media print {
  .sort-icon {
    display: none;
  }
}









































































































































































































































































































































































































































































































@import '../../../shared/assets/scss/core/_variables.scss';
@import '../../../shared/assets/scss/core/_breakpoints.scss';

.message-type-message {
  .message-container {
    display: flex;
    gap: 16px;
  }
  .avatar-container {
    margin-top: 20px;
  }
}

.message-bubble {
  flex-grow: 1;
}
.message-content {
  overflow: hidden;
}
.message-sent-time {
  font-size: 0.75rem;
}
.checkbox {
  align-self: flex-start;
}

.custom-right {
  top: 15px;
  margin-right: auto;
  margin-left: 0;
}

.custom-right-browser {
  left: 0;
  margin-right: 0px;
  margin-left: 0px;
  position: absolute !important;
  float: left !important;
}

.message-in-middle {
  position: absolute;
  right: -12px;
}

.custom-left {
  top: 15px;
  margin-left: auto;
  margin-right: 0;
}

.message-select-checkbox {
  width: 35px;
  height: 35px;
}

.checkbox-column {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.readers {
  margin-bottom: 3px;
}

.forward-message {
  font-size: 12px;
  cursor: pointer;
  .theme-employee & {
    color: $color-primary-dark-employee;
  }
  .theme-guardian & {
    color: $color-primary-dark-guardian;
  }
  .theme-child & {
    color: $color-primary-dark-child;
  }
  .theme-admin & {
    color: $color-primary-dark-admin;
  }
  .icon-Aula_redirect {
    font-size: 13px;
    margin-right: 0px;
    border-radius: 5px;
    padding: 2px 3px;
    .theme-employee & {
      color: $color-primary-dark-employee;
    }
    .theme-guardian & {
      color: $color-primary-dark-guardian;
    }
    .theme-child & {
      color: $color-primary-dark-child;
    }
    .theme-admin & {
      color: $color-primary-dark-admin;
    }
  }
}































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/presence/_presence.scss';

.alert.alert-danger {
  font-weight: 900;
  color: $color-primary-darker-guardian;
  &::before {
    color: $color-alert;
    background-color: $color-white;
  }
}

//Gallery scss
@import '../../core/mixins.scss';

.aula-gallery-container,
.aula-album-detail-container {
  .aula-modal & {
    background: $color-white;
  }

  .download-toolbar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 70px;
    z-index: 1048;
    color: var(--color-white);

    @include breakpoint-lg() {
      height: 80px;
    }

    .group-modal & {
      position: relative;
      top: 0;
    }

    &.to-top {
      position: fixed;
      top: 0;
    }

    .download-info-container {
      display: flex;
      align-items: center;
      margin-left: 20px;

      @include breakpoint-lg() {
        margin-left: 48px;
      }
    }

    .btn-download-album {
      text-transform: uppercase;
      position: relative;

      .aula-spinner {
        position: absolute;
        width: 60px;
        height: 60px;
        top: -5px;
        left: 70px;
      }
    }

    .icon-Aula_check {
      font-size: 25px;

      @include breakpoint-lg-down() {
        display: none;
      }
    }

    .icon-Aula_close {
      cursor: pointer;
      margin-right: 35px;

      @include breakpoint-lg() {
        margin-right: 50px;
      }
    }

    .theme-employee & {
      background: $color-primary-base-employee;
    }

    .theme-guardian & {
      background: $color-primary-base-guardian;
    }

    .theme-child & {
      background: $color-primary-base-child;
    }
  }
}

.gallery-modal.modal-fullscreen {
  .modal-aula {
    @include breakpoint-xl() {
      max-height: 80vh !important;
      height: 80vh !important;
    }
  }
}

.gallery-container {
  @include breakpoint-lg-down() {
    overflow: hidden;
  }

  .gallery-header {
    @include breakpoint-lg-down() {
      margin-top: 15px;
      margin-bottom: 5px;
      margin-right: 0;
    }
  }

  .gallery-body {
    padding: 5px 20px 50px 20px;

    @include breakpoint-lg() {
      padding-bottom: 25px;
    }

    .album-item {
      height: fit-content;
    }

    .album-item {
      height: fit-content;
    }

    .gallery-thumbnail {
      --aula-badge-box-shadow: var(--box-shadow-base);
      position: relative;

      .gallery-media-number {
        z-index: 1;
        position: absolute;
        top: 17px;
        right: 17px;
        height: 45px;
        width: 45px;
        opacity: 0.7;
        background-color: $color-grey-light;
        color: $color-primary-darker;
        font-size: 15px;
        font-weight: bold;
        line-height: 18px;
        text-align: center;
        padding: 13px 0;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
      }

      &.editor:hover,
      &.profile:hover,
      &.group:hover,
      &.gallery:hover {
        cursor: pointer;
      }
    }
  }
}

.aula-album-detail-container {
  .album-detail-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: $mobile-gallery-toolbar-z-index;
    background: $color-grey-light;

    @include breakpoint-lg() {
      position: relative;
      top: unset;
      left: unset;
      width: 100%;
      height: fit-content;
      z-index: unset;
    }

    .album-container {
      height: calc(100vh - 80px);
      width: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;

      @include breakpoint-lg() {
        height: fit-content;
        overflow: unset;
      }

      @include breakpoint-lg-down() {
        padding: 20px 20px 150px;
      }
    }
  }
}

.album-header {
  .back-to-gallery {
    border: 0;
    background: transparent;
  }

  p {
    font-size: 15px;
    font-weight: bold;
    line-height: 18px;
  }

  .metadata {
    font-size: 14px;
    line-height: 19px;

    @include breakpoint-lg-down() {
      span.group-list {
        display: inline-block;
      }
    }
  }

  @include breakpoint-lg-down() {
    p {
      font-weight: normal;
    }

    h1 {
      font-size: 16px;
    }
  }

  .mobile-dropdown {
    --dropdown-toggle-padding: 0;
    --dropdown-select-padding: 0 2px;
  }
}

.album-body,
.media-list {
  padding: 10px 0;
  @include breakpoint-lg() {
    padding: 0;
  }

  .upload-date-text {
    font-size: 14px;
    font-weight: 900;
    margin: 20px 0;
  }

  .media-thumbnail {
    max-width: 100%;
    border-radius: 4px;

    .media-detail {
      display: block;
      width: 100%;
      height: 100%;
      text-align: center;
      border-radius: 4px;
      background-color: var(--color-white);

      .selected-thumbnail-box {
        opacity: var(--selected-media-card-opacity);
      }

      .thumbnail-box {
        @include default-thumbnail();

        border-radius: 4px 4px 0 0;

        img {
          height: 100%;
          object-fit: cover;
        }

        .tag-profile {
          position: absolute;
          display: block;
          height: 40px;
          right: 15px;
          bottom: 10px;
        }
      }

      .text {
        color: $color-primary-darker;
        font-family: Lato;
        font-size: 13px;
        line-height: 16px;
        text-align: center;
      }

      .media-title {
        font-family: Lato;
        font-size: 16px;
        font-weight: 900;
        line-height: 19px;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 1.5rem;
        background-color: var(--color-white);
        color: var(--color-primary-darker);
      }
    }

    &:hover {
      cursor: pointer;

      .media-detail {
        .tag-profile {
          display: none;
        }
      }
    }
  }
}

.media-action-mobile-view .media-list {
  min-height: 0;
  flex-grow: 1;
  overflow-y: auto;
  padding-bottom: var(--menu-item-height, 60px);
}

.gallery-select {
  padding-left: 20px;
  padding-top: 10px;
  margin-bottom: 8px;

  @include breakpoint-lg() {
    padding: 0;
  }
}

.sorter,
.filter-sorter,
.album-detail-tag-dropdown {
  z-index: 1002;

  .icon-Aula_down-arrow {
    font-size: 0.4rem;
    margin-left: 5px;
  }

  .asc {
    a::before,
    &.sort::before {
      font-family: 'aula', Lato, serif;
      content: '\e988';
      margin-right: 0;
      margin-left: -16px;
    }
  }

  .desc {
    a::before,
    &.sort::before {
      font-family: 'aula', Lato, serif;
      content: '\e987';
      margin-right: 0;
      margin-left: -16px;
    }
  }
}

.media-tag-details-mobile {
  position: absolute;
  inset: 0;
  min-height: 100vh;
  background: var(--color-grey-light);
  z-index: var(--modal-z-index);

  .tag-details {
    padding: 20px;

    @include breakpoint-lg-down() {
      .el-input__inner {
        background: var(--color-white);
      }
    }

    .existing-tag {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;

      .tag-name {
        margin: 0 0.5rem;
        flex-grow: 1;
      }
    }
  }
}

.thumbnail-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--color-white);
  width: 130px;
  height: 130px;
  border: 5px solid var(--color-primary-light);
  border-radius: 50%;
  display: grid;
  place-items: center;
  background-color: var(--color-primary-base);

  &[data-variant='secondary'] {
    border-color: var(--color-white);
    background-color: transparent;
    text-shadow: var(--box-shadow-base);
    box-shadow: var(--box-shadow-base), var(--box-shadow-base) inset;
    opacity: 0.5;
  }
}

.small-thumbnail {
  background-color: var(--color-grey-base);
  width: 100px;
  height: 100px;

  .thumbnail-circle {
    width: 50px;
    height: 50px;
    border-width: 3px;

    .media-type-icon {
      --font-size: 25px;
    }
  }
}

.gallery-dropdown {
  .filter-name, .sort-name, div::before, .dropdown-item {
    font-size: 16px;
  }

  .gallery-sort {
    .dropdown-toggle {
      margin-left: 1rem;

      .sort-name {
        margin-left: 5px;
      }
    }

    .dropdown-menu {
      min-width: 175px;

      a::before {
        color: var(--color-primary-base) !important;
        margin-left: -22px;
        margin-right: 3px;
      }

      .dropdown-item {
        padding: 10px 16px 10px 32px;
      }
    }
  }
}

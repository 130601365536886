





























































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/core/animations.scss';
@import '../../../shared/assets/scss/core/icons.scss';
@import '../../../shared/assets/scss/components/login/onboarding.scss';

.primary-institution {
  --border-color: var(--color-grey-darkest);
  max-width: 352px;

  &[data-max-width] {
    max-width: 374px;
  }

  label {
    text-transform: none;
  }

  /deep/.contact-primary-container {
    justify-content: space-between;
    margin-top: 4px;
    margin-bottom: 8px;
    font-size: 16px;

    .help-box {
      .questionmark-icon {
        background-color: var(--color-grey-base);
      }
    }
  }

  /deep/ .institution-select {
    margin-bottom: 8px;
  }
}

.divider {
  border-bottom: 1px solid var(--color-primary-darker);
}







































































































.profile-filter-children {
  .checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .profile-option {
    display: flex;
    gap: 4px;
    align-items: center;
    background-color: var(--color-white);
    padding: 4px 8px;
    border-radius: 6px;
    transition: background-color ease-in 100ms;

    &:hover {
      background-color: var(--color-grey-light);
      cursor: pointer;
    }
    &[aria-disabled] {
      background-color: var(--color-grey-light);
      cursor: not-allowed;
    }
  }
  .profile-switch {
    margin-right: 0;
    margin-left: 4px;
  }
  .profile-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: auto;
  }
  #aula-header & {
    .profile-avatar.avatar {
      --width: 40px;
      --height: 40px;
      --font-size: 12px;
      --background-color: var(--color-white);
      --color: var(--color-primary-dark);
      --border: 1px solid var(--color-primary-dark);
    }
  }
}































.profile-frame {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.profile-avatar-container {
  flex: 1;
  overflow: hidden;
}

.profile-name-bar {
  background-color: var(--color-grey-dark);
  padding: 10px 7px;
}

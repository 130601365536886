














































































































































































































.message-inbox {
  transition-property: background-color, opacity;
  transition-duration: 300ms;
  transition-timing-function: var(--ease-in-out);
  .inbox-button {
    &[aria-expanded] {
      .expand-button .aula-icon {
        transform: rotate(180deg);
      }
    }
    .folder-name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex-grow: 1;
    }
  }
  .sub-folders {
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    .sub-folder {
      padding-left: 30px;
      .badge {
        margin-right: -4px;
      }
    }
  }
  .dropdown-icon {
    --font-size: 0.4rem;

    vertical-align: middle;
  }
  .expand-button {
    padding: 6px 4px;
    margin: -6px -4px;
    .aula-icon {
      display: block;
      transition: transform 300ms var(--ease-in-out);
    }
  }
  .add-folder-button {
    margin-left: 26px;
    margin-top: 4px;
    margin-bottom: 12px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
  /deep/.folder-actions {
    display: flex;
    align-items: center;
    padding: 4px;
    margin: -4px -6px;
  }
  /deep/.dropdown-select {
    min-width: 24px;
  }
  .badge {
    margin-right: -2px;
  }
  .inbox-badge {
    margin-right: 28px;
  }
}

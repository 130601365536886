@import '../../scss/core/_breakpoints.scss';

.modal-80 {
  max-width: 80%;
}

#loader-modal___BV_modal_outer_ {
  z-index: 9999 !important;
}

.aula-modal-lg {
  .modal-aula {
    overflow: visible;
    width: 728px;
  }
}

.modal-submodal {
  @include breakpoint-lg() {
    .modal-aula {
      z-index: $sub-modal-z-index;
      max-width: 400px;
      top: 20%;
    }

    .modal-aula-backdrop {
      z-index: $sub-modal-z-index;
    }
  }
}

.modal-calendarfeed {
  .modal-aula {
    h2 {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    @include breakpoint-xxl-down() {
      width: 80vw;
    }

    .modal-header {
      @include breakpoint-sm-up() {
        padding-left: 100px;
      }
    }

    .modal-body {
      @include breakpoint-sm-up() {
        padding: 50px 100px 0px !important;
      }
    }

    .modal-footer {
      @include breakpoint-sm-up() {
        padding: 20px calc(100px - 0.25rem) 50px;
      }
    }
  }
}

.aula-modal-container.group-modal .modal-aula {
  input:not(.el-select__input),
  textarea {
    background-color: var(--groupd-modal-input-background-color, $color-white);
  }
}

.modal-aula {
  z-index: var(--modal-z-index);
  max-width: var(--aula-modal-max-width, 80vw);
  left: 50%;
  top: 10vh;
  height: var(--modal-aula-height, unset);
  width: var(--modal-aula-width, unset);
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  box-shadow: var(--box-shadow-base);
  transform: translate(-50%, 0);
  background: $color-white;
  border-radius: 4px;

  .modal-body {
    max-width: inherit;
  }

  &[data-size='small'] {
    --aula-modal-max-width: 30vw;
  }

  .el-input__inner,
  input:not(.el-select__input),
  textarea {
    background-color: var(--modal-inner-input-background-color, var(--color-white));

    @include breakpoint-sm-up() {
      background-color: var(--modal-inner-input-background-color, var(--color-grey-light));
    }
  }

  @include breakpoint-lg-down() {
    top: 0;
    width: 100vw !important;
    height: 100%;
    max-width: 100vw !important;
    max-height: 100vh !important;
    display: flex;
    flex-flow: column;
    border-radius: 0;

    .modal-content {
      width: 100vw !important;
      height: 100%;
    }
  }

  .modal-content {
    background-color: transparent;
    box-shadow: 0 0 0 0 rgba(166, 166, 166, 0.5);
    border-radius: 0;
    border: 0;
    height: 100%;
  }

  .modal-header {
    background-color: var(--color-primary-dark);
    color: $color-white;
    height: 60px;
    min-height: 60px;
    border-radius: 0;
    font-family: Lato;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    display: block;
  }

  .modal-body {
    padding-top: var(--padding-top, 19px);
    padding-bottom: var(--padding-bottom, 0);
    padding-left: var(--padding-left, 19px);
    padding-right: var(--padding-right, 19px);
    background-color: var(--color-base, var(--color-white));
    overflow: auto;
    max-height: var(--aula-modal-max-height, calc(80vh - 150px));

    @include breakpoint-lg-down() {
      max-height: unset;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .theme-employee & {
      color: $color-primary-darker-employee;
    }

    .theme-guardian & {
      color: $color-primary-darker-guardian;
    }

    .theme-child & {
      color: $color-primary-darker-child;
    }

    .theme-admin & {
      color: $color-primary-darker-admin;
    }
  }

  .modal-footer {
    border-top: 0;
    color: $color-primary-light;
    background-color: var(--color-base, var(--color-white));
    text-align: right;
    min-height: 80px;

    @include breakpoint-sm-down() {
      padding-bottom: 50px;
      min-height: 60px;
    }

    button {
      border-radius: 5px;
      height: 50px;
      min-width: 120px;

      &.btn-primary {
        font-size: 15px;
        font-weight: 500;
        font-type: lato;
        padding: 13px 15px;
        position: relative;
      }
    }
  }
}

.modal-medium {
  --modal-aula-width: 70vw;
  --modal-aula-height: 80vh;
  --padding-top: 0;
  --padding-left: 0;
  --padding-right: 0;
  --padding-bottom: 0;
  .modal-body {
    flex: 1;
    min-height: 0;
  }
}

.modal-no-space {
  --padding-top: 0;
  --padding-left: 0;
  --padding-right: 0;
  --padding-bottom: 0;
}

.modal-fullscreen {
  > .modal-aula {
    top: 0;
    left: 0;
    position: fixed;
  }

  > .modal-dialog,
  > .modal-aula {
    width: 100% !important;
    max-width: 100% !important;
    height: 100% !important;
    max-height: 100% !important;
    margin: 0;
    transform: unset;

    @include breakpoint-xl() {
      left: 5%;
      top: 35px;
      width: 90vw !important;
      max-width: 90vw !important;
      height: 90vh !important;
      max-height: 90vh !important;
      margin: 0;
      transform: unset;

      .drawer & {
        width: 70vw !important;
        max-width: 70vw !important;
      }
    }

    > .modal-content {
      width: 100% !important;
      max-width: 100% !important;

      @include breakpoint-sm-up() {
        height: 100% !important;
        max-height: 100% !important;
      }

      @include breakpoint-sm-up() {
        .drawer & {
          width: 70vw !important;
          max-width: 70vw !important;
        }
      }

      > .modal-body {
        padding: 0 !important;
        overflow-y: auto;
      }

      @include breakpoint-lg-down() {
        padding-bottom: 0;
      }
    }
  }

  .group-modal & {
    > .modal-aula {
      @include breakpoint-xl() {
        top: 35px;
        left: 5%;
        width: 90vw !important;
        max-width: 90vw !important;
      }
    }
  }
}

.modal-aula-backdrop {
  z-index: var(--modal-z-index);
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  &.show {
    opacity: 0.4;
  }

  &.no-backdrop {
    background: none;
  }
}

.modal-open .sticky-top {
  z-index: 0 !important;
}

//---- INDIVIDUAL MODAL STYLING ----//
.request-vacation-modal {
  .modal-aula {
    .modal-footer {
      padding-bottom: 30px;
      padding-right: 40px;
    }

    .modal-body {
      padding: 40px 40px 20px;

      @include breakpoint-sm-down() {
        padding: 20px;
      }

      .datetime-input {
        width: 100%;
      }
    }

    label {
      font-size: 15px;
      font-weight: bold;
      text-transform: uppercase;
    }

    .el-input__inner,
    input {
      background: $color-grey-light;
    }
  }
}

.survey-modal {
  .modal-body {
    @include breakpoint-lg() {
      --padding-top: 34px;
      --padding-left: 34px;
      --padding-right: 34px;
      --padding-bottom: 8px;

      h4 {
        margin-bottom: 0.8rem;
      }
    }

    .survey-content {
      @include breakpoint-lg() {
        min-width: 400px;
      }

      a {
        color: var(--color-primary-dark);
        text-decoration: underline;
        &:hover {
          color: var(--color-primary-darker);
        }
      }
    }
  }

  .modal-footer {
    @include breakpoint-lg() {
      padding-right: 26px;
      gap: 20px;
      margin-bottom: 20px;
    }
  }
}

.general-information-modal {
  .modal-body {
    @include breakpoint-lg() {
      --padding-top: 34px;
      --padding-left: 34px;
      --padding-right: 34px;
      --padding-bottom: 34px;

      h4 {
        margin-bottom: 0.8rem;
      }
    }

    .general-information-content {
      @include breakpoint-lg() {
        min-width: 400px;
      }

      a {
        color: var(--color-primary-dark);
        text-decoration: underline;
        &:hover {
          color: var(--color-primary-darker);
        }
      }
    }
  }
}

.modal-right-of-disposal {
  @include breakpoint-lg-down() {
    .mobile-topbar-container .first-column {
      max-width: none;
    }
  }
}

.document-warning-modal {
  --padding-top: 20px;
  --padding-left: 20px;
  --padding-right: 20px;
}

.document-remove-association-warning-modal {
  --padding-top: 16px;
  --padding-left: 16px;
  --padding-right: 16px;
  --aula-modal-max-width: 400px;

  p {
    margin-bottom: 0;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 16px 0;
    display: flex;
    flex-direction: column;
    gap: 8px;

    li {
      height: 36px;
      padding-left: 8px;
      border-radius: 6px;
      background-color: var(--color-grey-base);
      display: flex;
      align-items: center;
      gap: 8px;

      .document-title {
        display: block;
        height: 24px;
        line-height: 24px;
        width: 90%;
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .icon-container {
        --width: 24px;
        border-radius: 4px;

        .aula-icon {
          --font-size: 18px;
        }
      }
    }
  }
}

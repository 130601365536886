

















































































































































































































































































































































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/login/onboarding.scss';
.aula-onboarding /deep/ a {
  font-weight: bold;
  color: $color-picker-button-blue;
  &:hover {
    text-decoration: underline;
  }
}































































































.thread-actions-standard {
  gap: 2px;
}
.draft {
  .draft-hidden {
    visibility: hidden;
  }
}

button.thread-action {
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-primary-base);
  transition: background-color 100ms ease-in, transform 100ms ease-in;

  &:hover {
    background-color: var(--color-grey-light);
    transform: scale(120%);
  }

  .aula-icon {
    font-size: 12px;
    vertical-align: middle;
    &.icon-Aula_star_solid {
      color: var(--color-alert);
    }
  }
}

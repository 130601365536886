










[data-sidebar='menu-button'] {
  background-color: var(--sidebar-background);
  font-size: 14px;
  padding: 10px 8px;
  text-align: start;
  display: flex;
  gap: 8px;
  align-items: center;
  border-radius: 4px;

  &:hover {
    background-color: var(--sidebar-accent);
  }
  &[aria-selected] {
    background-color: var(--sidebar-primary);
    font-weight: bold;
  }
}




























































.phone-container {
  white-space: nowrap;
}
.phone-number {
  width: 100px;
}

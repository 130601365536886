@import '../../core/variables.scss';
@import '../../core/breakpoints.scss';

.group-search {
  @include breakpoint-xl() {
    display: flex;
    align-items: center;
  }
  .form-inline .input-group {
    width: 100%;
  }
  .group-search-input {
    padding-right: 0;
    border-radius: 0;
    width: 180px;
    background-color: #ecf2f3;
    border: none;
    font-weight: lighter;
    margin: 0;
    min-height: 60px;
  }
  .input-group-prepend {
    top: 18px;
    position: absolute;
    right: 15px;
  }

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $color-primary-darker;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: $color-primary-darker;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: $color-primary-darker;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: $color-primary-darker;
  }
}

.group-search-mobile {
  padding: 0px 0px 19px 0px;
}

.nav-item-option {
  &:hover {
    .nav-link {
      background: transparent !important;
    }
    text-decoration: underline;
  }
}

.no-border-radius {
  border-radius: 0;
}

.icon-Aula_group {
  font-size: 1.5em;
}

.icon-header {
  color: #fff;
  cursor: pointer;
  @include breakpoint-xl() {
    display: inline-block;
    margin-top: 20px;
    font-size: 30px;
  }
}

.icon-header-search-container {
  padding-top: 15px;
  text-align: center;
  width: 58px;
  height: 58px;
  .icon-header-search {
    color: #fff;
    cursor: pointer;
    font-size: 21px;
  }
}

.icon-header-close-container {
  background-color: transparent;
  text-align: center;
  padding: 20px 0 0 0;
  width: 58px;
  height: 58px;
  .icon-header-close {
    color: #fff;
    cursor: pointer;
  }
}

.padding-left {
  padding-left: 50px;
  @include breakpoint-xl-down() {
    padding-left: 20px;
  }
}

.group-header {
  width: 100%;
  margin: 2px 0 -2px 0;
  @include breakpoint-xl() {
    padding-top: 10px;
  }

  .group-title {
    color: $color-white;
    letter-spacing: 1px;
    font-weight: normal;
    font-size: 15px;
    @include breakpoint-xl-down() {
      text-transform: uppercase;
      margin: 0 0 0 35px;
      height: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 58px;
    }
    @include breakpoint-xl() {
      vertical-align: 4px;
      padding-bottom: 30px;
      padding-left: 15px;
      font-weight: bold;
      font-size: 36px;
      margin-top: 10px;
    }
    i {
      font-size: 25px;
    }
  }
}

.search-module {
  width: 100%;
  font-size: 14px;
  padding: 5px 0px 0px 8px;
  .search-input {
    border: none;
    border-radius: none;
  }
  .input-group-addon:focus-visible:active {
    .theme-employee & {
      color: $color-primary-darker-employee;
    }
    .theme-guardian & {
      color: $color-primary-darker-guardian;
    }
    .theme-child & {
      color: $color-primary-darker-child;
    }
    .theme-admin & {
      color: $color-primary-darker-admin;
    }
    background-color: white;
  }
  .input-group-addon {
    background-color: white;
    padding: 10px;
    height: 50px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    .theme-employee & {
      color: $color-primary-darker-employee;
    }
    .theme-guardian & {
      color: $color-primary-darker-guardian;
    }
    .theme-child & {
      color: $color-primary-darker-child;
    }
    .theme-admin & {
      color: $color-primary-darker-admin;
    }
  }
  @include breakpoint-lg() {
    width: 100%;
    padding: 0;
    position: relative;
    .form-control {
      border-radius: 0;
    }
    .input-group-addon {
      padding-left: 15px;
      height: 60px;
      border-radius: 0;
      .theme-employee & {
        color: $color-primary-darker-employee;
      }
      .theme-guardian & {
        color: $color-primary-darker-guardian;
      }
      .theme-child & {
        color: $color-primary-darker-child;
      }
      .theme-admin & {
        color: $color-primary-darker-admin;
      }
      .icon-Aula_search {
        font-size: 28px;
      }
    }
  }
}

.icon-Aula_app_arrow_slideback {
  position: absolute;
  font-size: 18px;
  top: 15px;
  left: 15px;
}

.col-8.padding-left.mt-2 {
  @include breakpoint-xl-down() {
    margin: 0 !important;
    padding: 0;
  }
}

.paddingContent {
  @include breakpoint-xl() {
    padding: 0 40px 40px 40px;
  }
}

#leave-modal {
  position: absolute;
  left: 40%;
  top: 40%;
  z-index: 9999;
}

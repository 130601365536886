











































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































@import '../../../shared/assets/scss/core/breakpoints.scss';

.avatar-group {
  --avatar-size: 40px;
}

.search-input {
  padding-bottom: 20px;
  @include breakpoint-lg() {
    min-width: 400px;
  }
}

.subscription-header-container {
  display: grid;
  grid-template-columns: auto 1fr 124px;
  grid-gap: 24px;

  max-height: 1500px;
  padding: 8px 32px;
  border-bottom: 1px solid var(--color-grey-dark);

  .subscription-metadata {
    min-width: 0;
    height: 100%;
  }

  @include breakpoint-lg-down() {
    grid-template-columns: 1fr;
    background-color: var(--color-grey-dark);
    padding: 0;
    grid-gap: 0;
    position: sticky;
    top: 0;

    .message-recipients-popover {
      height: 60px;
      background-color: var(--color-primary-darker);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      /deep/ .message-recipients-target {
        flex-direction: row;
        justify-content: center;

        .message-recipients-button {
          display: none;
        }

        .avatar-group {
          .avatar {
            --width: 32px;
            --height: 32px;
          }
        }
      }
    }

    /deep/.subscription-metadata {
      padding: 8px 16px;
      .subscription-title {
        min-height: 20px;
        font-size: 16px;
      }

      .subscription-creator {
        font-size: 12px;
      }

      .icon-container {
        .aula-icon {
          --font-size: 20px;
          padding-top: 6px;
        }
      }
    }

    .action-buttons-container {
      display: none;
    }
  }
}

.subscription-functionalities {
  background-color: p var(--color-grey-light);
  top: 60px;
  @include breakpoint-lg {
    top: 0;
  }
  ul {
    max-width: 450px;
    margin: 0 auto;
    @include breakpoint-lg-down() {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}

.colored {
  color: var(--color-alert);
}

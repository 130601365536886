





































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/presence/presence-checkin-dashboard';
@import '../../../shared/assets/scss/components/presence/presence-employee';

.line {
  padding-bottom: 30px;
  margin-bottom: 20px;
  border-bottom: 1px solid $color-grey-dark;
}

.children-registration {
  --sleep-time-border-color: var(--color-primary-darker);
}

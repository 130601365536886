








































































.resizable-container {
  --resizer-color: var(--color-grey-light);

  position: relative;
}

.resizer {
  position: absolute;
  width: 15px;
  z-index: var(--resizer-z-index, 1);
  cursor: ew-resize;

  &::before {
    content: '';
    position: absolute;
    width: 2px;
    height: 100%;
    background-color: var(--resizer-color);
    transition-property: width, background-color;
    transition-duration: 0.2s;
    transition-timing-function: var(--ease-in-out);
  }

  &:is(:hover, :focus)::before {
    width: 6px;
    left: 0;
    background-color: var(--color-grey-base);
  }

  &.resizer-right {
    top: 0;
    right: -15px;
    height: 100%;
  }
}

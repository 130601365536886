





















.system-message {
  padding: 0 64px 20px 64px;
  border-bottom: 1px solid var(--color-read-message);

  .system-message-header {
    font-weight: bold;
  }
}










































































































.contact-name-cell {
  word-break: break-all;
}

.birthday-cell {
  width: 160px;
  max-width: 160px;
}

@media print {
  .table {
    .head {
      .heading {
        font-size: 20px;
        padding: 10px;
      }
    }

    .body {
      .cell {
        padding: 10px;
        font-size: 18px;
      }
    }
  }
}

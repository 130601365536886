
































































































































































































































































































































































































































































































































































































































































































































































@import '../../assets/scss/core/variables';
@import '../../assets/scss/core/breakpoints';
@import '../../assets/scss/elements/notificationSetting';

footer {
  @include breakpoint-lg-down() {
    position: fixed !important;
    bottom: 80px;
    top: unset;
  }
}

.settings-label {
  display: block;
  &.large {
    margin-bottom: 15px;
  }
}

.red-border {
  .notification-settings-body-mobile & {
    display: block;
  }
}












[data-sidebar='group-content'] {
  width: 100%;
}

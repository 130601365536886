










[data-sidebar='group-label'] {
  width: 100%;
  margin-bottom: 8px;
}

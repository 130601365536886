










.filtering-group-warning {
  font-weight: 900;
  padding: 20px;
}

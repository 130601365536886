


































































































































































































































































































































































































































































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/gallery/gallery';
.icon-Album {
  font-size: 15px;
}
.gallery-thumbnail /deep/ {
  .badge-notification {
    left: 5px;
    top: 5px;
    z-index: 1;
  }
}

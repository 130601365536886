




























































































































































































































































































































































































































































































































































































































































































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/presence/_presence-employee.scss';

.mobile-statistic {
  min-height: 45px;
}

.user-name {
  .alert {
    &.alert-danger {
      &::before {
        top: -9px;
      }
    }
  }
}

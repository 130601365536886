



































































































.card-text {
  text-transform: capitalize;
}

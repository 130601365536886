




































































































































































































































































































































































































































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/core/scrollbar.scss';
@import '../../../shared/assets/scss/core/animations.scss';

.threads {
  overflow-x: hidden;
  flex-grow: 1;
  flex-basis: 0;

  @include breakpoint-lg {
    position: relative;
    overflow-y: scroll;
    //-webkit-overflow-scrolling: touch;
    overflow-x: hidden;
  }
  @include breakpoint-lg-down() {
    padding-bottom: 80px;
  }
  .searchField {
    position: relative;
    width: 100%;
    margin-bottom: 4px;
    height: 50px;
    font-size: 14px;
    border-radius: 0px;
  }
  .firefox & {
    padding-right: 0 !important;
  }
  .loading-spinner {
    margin: 24px auto;
    display: flex;
  }
}

.point-left {
  transform: rotate(-90deg);
  display: block;
  width: 30px;
  font-size: 25px;
}

.is-from-search {
  max-height: none;
}

.backToInbox {
  padding: 14px 20px 14px 0px;
  background-color: $color-grey-darker;

  .arrow {
    min-width: 64px;
  }

  .text {
    font-size: 12px;
  }

  i {
    margin-left: 10px;
    cursor: pointer;
    margin-top: -15px;
  }
}

.scrollbar {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  .firefox & {
    padding-right: 17px;
  }

  &:hover {
    padding-right: 0;
  }
}
.searchArea {
  /deep/ .input-group.search-input-group {
    .input-group-prepend {
      right: 25px;
      top: 15px;
    }
  }
  @include breakpoint-lg {
    height: 54px;
    width: 100%;
    border-bottom: solid 2px $color-grey-light;
  }
}

/deep/.dragging-subscription {
  opacity: 0.3 !important;
  box-shadow: var(--box-shadow-base);
  .subscription-touch-actions {
    display: none;
  }
}
/deep/.ghost-subscription {
  pointer-events: none;
  .subscription {
    background-color: var(--color-white);
    border-radius: 4px;
    outline: 1px dashed var(--color-primary-darker);
    outline-offset: -1px;
    * {
      opacity: 0.6;
    }
  }
}


















































































































































































































































































@import '../../../shared/assets/scss/core/breakpoints.scss';

[class*='col'] {
  margin-top: 20px;

  @include breakpoint-lg() {
    margin-top: 30px;
  }
}

.advanced-search-folder-label {
  text-transform: unset;
  font-weight: unset;
}























































































































.profile-filter-standard {
  .selected-profiles {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    .profile {
      display: flex;
      gap: 4px;
      align-items: center;
      background-color: var(--color-white);
      padding: 8px 12px;
      border-radius: 6px;
      margin-bottom: 8px;
    }
    .close-button {
      display: flex;
      margin-left: auto;
      padding: 2px;
    }
    .profile-name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .add-button {
    display: flex;
    gap: 8px;
    align-items: center;
  }
  .add-icon-container {
    --background-color: var(--color-primary-darker);
    --color: var(--color-white);
  }
}































































































































































































































































































































































































































































































































































































































































































































































































































































































































.album-header {
  --group-page-label-margin: 0 10px;
  --groupd-modal-input-background-color: var(--color-grey-light);
  --input-background-base-color: var(--color-grey-light);

  .album-detail-tag-dropdown {
    --dropdown-menu-min-width: 400px;
  }
}

.action-button {
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: inherit;
  padding: 0 5px;
  margin: 0 5px;

  .download-spinner {
    position: absolute;
    right: -40px;
    width: 50px;
    height: 50px;
  }
}

.album-detail-dropdown {
  .filter-name,
  .sort-name,
  div::before,
  /deep/ .dropdown-item {
    font-size: 16px;
  }

  .album-detail-sort {
    /deep/ .dropdown-toggle {
      margin-left: 1rem;

      .sort-name {
        margin-left: 3px;
      }
    }

    /deep/ .dropdown-menu {
      min-width: 110px;

      a::before {
        color: var(--color-primary-base) !important;
      }

      .dropdown-item {
        padding: 10px 16px 10px 31px;
      }
    }
  }
}

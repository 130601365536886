









































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.search-results {
  @include breakpoint-lg-down() {
    padding-bottom: var(--menu-item-height);
  }
}

.margin-top-120 {
  width: 100%;
  margin: 120px 0 0 0;
}

.search-result-container /deep/ .menu-icon {
  position: relative;
  top: -12px;
  max-width: 80%;
}















































































































































































































@import '../../../shared/assets/scss/core/_breakpoints.scss';

.group-members-container {
  @include breakpoint-lg-down() {
    /deep/ {
      .table .table-row.body .table-cell .item span:first-child {
        display: none;
      }
    }
  }

  .group-member {
    --table-pointer-event: none;

    margin-top: 20px;
  }

  .group-empty-text {
    margin-bottom: 8px;
  }
}

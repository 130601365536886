



































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/core/animations.scss';
.aula-groups {
  border-right: 1px solid $color-white;
  border-left: 1px solid $color-white;
  .header-dropdown {
    --aula-badge-box-shadow-color: var(--color-primary-dark);
    &.active,
    &:hover {
      --aula-badge-box-shadow-color: var(--color-primary-darker);
    }
  }
}
img.menu-icon {
  width: var(--default-menu-icon-size);
}

.header-dropdown-menu {
  bottom: 0;
  height: auto;
  position: fixed;
}

.small-text {
  text-transform: uppercase;

  .early-student & {
    color: #fff !important;
    text-transform: capitalize;
    font-family: Baloo;
    font-weight: normal;
    font-size: 15px !important;
  }
}

















































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.groups-list {
  @include breakpoint-lg-down() {
    overflow-x: auto;
    width: 100vw;
    height: 100vh;
    background-color: $color-primary-base-employee;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1055;
  }
  .group-item {
    position: relative;
    background-color: var(--color-grey-dark);
    padding: 1rem 0.75rem;
    margin-bottom: 2px;
    font-weight: bold;
    .group-item-name {
      margin: 0 5px;
      min-width: 0;
      flex-grow: 1;
    }
    .group-item-badge {
      --position: static;
    }
  }
  .header-dropdown-title {
    @include breakpoint-lg-down() {
      display: block;
      text-align: center;
      height: 65px;
      width: 100%;
      font-size: 18px !important;
    }
    .header-dropdown-close {
      @include breakpoint-lg-down() {
        display: none;
      }
    }
    .group-back {
      position: absolute;
      top: 20px;
      left: 20px;
      display: block;
      @include breakpoint-lg() {
        display: none;
      }
    }
  }
}

/deep/ .groups-list {
  .tooltip {
    .arrow:before {
      border-top-color: $group-tooltip-background;
    }

    .tooltip-inner {
      background-color: $group-tooltip-background !important;

      .theme-employee & {
        color: $color-primary-darker-employee;
      }

      .theme-guardian & {
        color: $color-primary-darker-guardian;
      }

      .theme-child & {
        color: $color-primary-darker-child;
      }

      .theme-admin & {
        color: $color-primary-darker-admin;
      }
    }
  }
}

//Theme styling
.theme-admin {
  .groups-list {
    @include breakpoint-lg-down() {
      background-color: $color-primary-dark-admin;
    }
  }
}
.theme-guardian {
  .groups-list {
    @include breakpoint-lg-down() {
      background-color: $color-primary-base-guardian;
    }
  }
}
.theme-employee {
  .groups-list {
    @include breakpoint-lg-down() {
      background-color: $color-primary-base-employee;
    }
  }
}
.theme-child {
  .groups-list {
    @include breakpoint-lg-down() {
      background-color: $color-primary-base-child;
    }
  }
}












































.avatar {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-grey-base);
}

.profile-initials {
  text-transform: uppercase;
}

.profile-image {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

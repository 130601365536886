

























.switch-label {
  text-transform: none;
  font-weight: unset;
  margin-left: 10px;
  margin-bottom: 0;
}











































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
.aula-documentsFolderPicker-container {
  .parent {
    position: relative;
    .folderFold {
      position: absolute;
      top: -24px;
      right: 15px;
      cursor: pointer;
      transition: transform 0.25s;
    }
  }
  .sub-folders {
    display: none;
    padding-left: 10px;
  }
  .showSub {
    & > .sub-folders {
      display: block;
    }
    & > .folderFold {
      transform: rotate(90deg);
    }
  }
  .folderItem {
    padding: 5px 10px;
    margin-bottom: 1px;
    &:hover {
      background-color: $color-grey-light;
    }
  }
  .active {
    > .folderItem,
    > .parent > .folderFold {
      background-color: $color-darkblue;
      color: $color-white;
    }
  }
}

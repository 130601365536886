





























.thumbnail-variables {
  --height: 100%;
  --width: 100%;
}
.thumbnail {
  height: var(--height);
  width: var(--width);
  overflow: hidden;
  .thumbnail-image {
    --height: 100%;
    --width: 100%;
  }
}

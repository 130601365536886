


























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.message-icon {
  font-size: 24px;
  padding-right: 5px;
}

.label {
  cursor: pointer;
}

a {
  font-size: 12px;
  color: $color-help-text;
}

#message-new {
  padding: 0 40px 20px 40px;
  width: 100%;
  align-self: center;
  overflow: auto;
  flex-grow: 1;

  @include breakpoint-lg-down {
    padding: 0 20px;
    width: unset;
    align-self: stretch;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  label {
    margin-top: 20px;
    padding: 3px;
  }

  @include breakpoint-lg {
    /deep/ .mce-edit-area iframe {
      min-height: 200px;
    }
  }
}

.cancel-message-link {
  margin-right: 20px;
  font-size: 14px;
  line-height: 36px;
  cursor: pointer;
}

.send-btn {
  width: 137px;
  height: 50px;
}

.btn-light,
.btn-light:not([disabled]):not(.disabled) {
  &:hover,
  &:active {
    color: $color-grey;
    background-color: transparent;
    border: none;
  }
}

.new-message-heading {
  padding-top: 20px;
  font-size: x-large;
  font-weight: bold;
}

.recipient-input {
  border: 1px solid $color-white;
  padding: 12px 18px;
}

.inline-checkbox {
  display: inline-block;
}

.replied-recipient {
  --input-border-color: var(--color-grey-darker);
}

.message-body {
  padding: 18px;
  border-radius: 8px;
  border: 1px solid var(--color-grey-darker);
  font-size: 15px;
  word-break: break-all;
  position: relative;
  background-color: var(--color-grey-light);
}

/deep/ .custom-checkbox {
  margin-right: 0;
  margin-top: 0 !important;

  .custom-control-description {
    margin-left: 26px;
    font-weight: normal;

    i {
      margin-left: 11px;
    }
  }
  input:checked ~ .custom-control-description i {
    color: $color-alert;
  }
}

.sensitive-msg-container {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
}

.message-utility-container {
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  min-height: 25px;
}

/deep/ .aula-search {
  --select-input-tags-padding-right: 28px;
}

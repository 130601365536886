










[data-sidebar='group'] {
  position: relative;
  padding: 8px;
  &:not(:last-child)::after {
    content: '';
    position: absolute;
    right: 8px;
    left: 8px;
    border-bottom: 1px solid var(--color-grey-base);
  }
}







































































































































































































































































































































































































































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.aula-circle-message {
  font-size: 10px;
  margin-top: 18px;
  @include breakpoint-tablet-portrait() {
    font-size: 14px;
    margin-top: 24px;
  }
}

.message-badge-notification {
  --position: relative;
}

.messages-toolbar {
  font-size: 14px;
  background-color: $color-grey-base;
  outline: none;
  padding-left: 4px;

  .navbar-nav {
    flex-direction: row;
    align-items: center;
  }

  .navbar-nav .btn,
  .nav-item .btn span {
    display: flex;
    align-items: center;
  }
  .auto-reply {
    padding: 8px;
  }

  /deep/ .toolbar-button {
    padding: 8px;
    min-height: 38px;
  }

  .filters-option {
    /deep/ .dropdown-menu {
      max-width: 200px !important;
      width: 200px;
    }
  }
  .dropdown-menu {
    .form-group {
      min-width: 190px;
      margin: 0;
      padding: 0 5px;
    }
    input[type='text'] {
      border: solid 1px $color-form-border;
      padding: 8px 9px;
      width: 220px;
    }
  }
  .new-content-icon {
    position: relative;
    font-weight: bold;
    padding: 0 5px;
    height: 16px;
    top: -2px;
    background: $color-alert;
    line-height: 16px;
    display: inline-block;
    font-size: 12px;
    color: $color-grey-base;
    border-radius: 12px;
  }

  .dropdown-select {
    padding: 0;
  }

  .tool-link,
  .dropdown-select {
    .theme-employee & {
      color: $color-primary-darker-employee;
    }
    .theme-guardian & {
      color: $color-primary-darker-guardian;
    }
    .theme-child & {
      color: $color-primary-darker-child;
    }
    .theme-admin & {
      color: $color-primary-darker-admin;
    }
    &:hover {
      text-decoration: none;
    }
    .icon {
      position: relative;
    }
    .icon-Aula_down-arrow,
    .icon-Aula_up-arrow {
      font-size: 6px;
    }
    .icon-Aula_folder {
      font-size: 17px;
    }
    .icon-Aula_write {
      top: 14px;
      font-size: 18px;
      @include breakpoint-tablet-portrait() {
        top: 20px;
      }
    }
    .dropdown-item {
      align-items: center;
      cursor: pointer;
      display: flex;
      padding-right: 10px;

      span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.dropdown-folder {
        padding-left: 2.25rem;
      }
      font-weight: bold;
    }
    .dropdown-header {
      background-color: $color-dropdown-focus;
    }
  }
  .desc,
  .asc {
    display: flex;
    align-items: center;
  }

  .dropdown-toggle {
    .desc::before {
      font-family: 'aula', Lato, serif;
      content: '\e987';
    }
    .asc::before {
      font-family: 'aula', Lato, serif;
      content: '\e988';
    }
  }

  .dropdown-menu {
    .desc /deep/a::before {
      font-family: 'aula', Lato, serif;
      content: '\e987';
      position: absolute;
      left: 14px;
    }
    .asc /deep/::before {
      font-family: 'aula', Lato, serif;
      content: '\e988';
      position: absolute;
      left: 14px;
    }

    .desc /deep/a::before,
    .asc /deep/a::before {
      .theme-employee & {
        color: $color-primary-base-employee;
      }
      .theme-guardian & {
        color: $color-primary-base-guardian;
      }
      .theme-child & {
        color: $color-primary-base-child;
      }
    }
  }

  .scrollable {
    max-height: 70vh;
    overflow-y: auto;
  }
  .is-not-empty {
    color: red !important;
  }
  .autoreply-text {
    display: none;
    @include breakpoint-xs-up() {
      display: initial;
      position: relative;
      margin-left: 5px;
    }
  }
  .icon-Aula_settings {
    font-size: 19px;
  }
}

.badge-notification-dropdown {
  left: -6px !important;
  top: 7px !important;
  .badge {
    width: 20px;
    height: 20px;
  }
}

.sortingmenu,
.filters-option {
  --dropdown-toggle-button-max-width: 150px;
  @include breakpoint-lg() {
    --dropdown-toggle-button-max-width: 300px;
  }

  position: relative;
  top: 0px;

  & /deep/ .dropdown-toggle {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}
.folder-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
  @include breakpoint-lg-down {
    max-width: 100%;
    margin-right: 0;
  }
}
.mobile-message-toolbar {
  justify-content: unset;
  padding-left: 8px;
}
.mobile-nav-item {
  max-width: 45vw;

  /deep/ button.dropdown-toggle {
    width: 100%;
  }
}

.folder-toggle {
  display: flex;
  gap: 4px;
  align-items: center;
  max-width: 180px;
  padding: 8px;
  line-height: 22px;
  margin-right: 8px;
  border-radius: 4px;
  transition-property: color, background-color;
  transition-duration: 300ms;
  transition-timing-function: var(--ease-in-out);
  &:hover {
    background-color: var(--color-grey-light);
  }
  &[data-active] {
    background-color: var(--color-primary-darker);
    color: var(--color-white);
  }
  @include breakpoint-lg-down() {
    max-width: 120px;
  }
}








































































.profile-filter {
  padding: 1rem;
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;
  }
  .header-icon {
    --font-size: 1.25rem;
    margin-right: 0.5rem;
  }
  .heading-title {
    font-size: 18px;
    text-transform: uppercase;
  }
  .close-button {
    font-size: 1.25rem;
    margin-left: auto;
  }
  .close-icon {
    --font-size: 1.25rem;

    padding: 0.5rem;
  }
}

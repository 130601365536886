













































































































































































































@import '../../assets/scss/core/mixins.scss';

.thread-standard-item {
  position: relative;
  height: 100px;
  display: flex;
  gap: 12px;
  background-color: var(--color-white);
  padding: 12px 26px 12px 16px;

  .thread-avatars {
    margin-top: 6px;
    height: min-content;
    min-height: 40px;
    min-width: 58px;
    position: relative;
    flex-shrink: 0;

    .thread-checkbox {
      position: absolute;
      top: calc(50% - 12px);
      left: calc(50% - 8px);
      display: none;
      transition: transform 100ms ease-in;
      &:hover {
        transform: scale(120%);
      }
    }
  }

  .status-indicator {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 10px;
    height: 100%;
    background-color: var(--color-grey-base);
    transition: width 150ms ease-in, background-color 150ms ease-in;
  }

  @include mouse-hover {
    background-color: var(--color-grey-base);

    .thread-checkbox {
      display: block;
    }
    .thread-actions {
      visibility: visible;
      width: 110px;
    }
    .status-indicator {
      width: 16px;
      background-color: var(--color-primary-dark);
    }
    .thread-standard-avatars {
      opacity: 0.25;
      pointer-events: none;
    }
  }

  &[data-unread] {
    background-color: var(--color-grey-base);
    .status-indicator {
      background-color: var(--color-primary-darker);
    }
    .thread-last-message,
    .thread-time {
      font-weight: bold;
    }
  }
  &[data-active] {
    background-color: var(--color-grey-base);
    .status-indicator {
      background-color: var(--color-primary-light);
      width: 16px;
    }
    .thread-last-message,
    .thread-time {
    font-weight: bold;
    }
  }
  &[data-bundle-open] {
    .thread-content .bundle-icon {
      transform: rotate(180deg);
    }
  }
  &[data-bundle-item] {
    .thread-content {
      display: none;
      &.partial {
        display: block;
        .thread-creator,
        .first-recipient {
          font-weight: bold;
        }
      }
    }
    .thread-avatars {
      margin-top: 4px;
      min-width: 40px;
    }
  }
  &[data-selecting] {
    .thread-checkbox {
      display: block;
    }
    .thread-standard-avatars {
      opacity: 0.25;
      pointer-events: none;
    }
  }

  .thread-actions {
    visibility: hidden;
    margin-left: auto;
    margin-right: -4px;
    flex-wrap: nowrap;
    flex-shrink: 0;
    width: 42px;
    justify-content: flex-end;
    align-self: flex-start;
    overflow: hidden;
  }

  .thread-standard-avatars {
    align-self: flex-start;
    flex-shrink: 0;
  }

  .thread-content {
    margin-right: auto;
    min-width: 0;
    flex-grow: 1;

    &.partial {
      display: none;
    }

    section {
      display: flex;
      flex-grow: 1;
      gap: 4px;

      &.first {
        align-items: flex-end;
      }
      &.second {
        align-items: center;
      }
      &.third {
        align-items: flex-end;
      }
    }

    .thread-time {
      font-size: 12px;
      color: var(--color-grey-dim);
      white-space: nowrap;
      margin-left: auto;
    }

    .thread-title {
      font-size: 16px;
      font-weight: bold;
      height: 22px;
    }
    .first-recipient,
    .thread-creator,
    .bcc-indicator,
    .thread-participant {
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .bcc-indicator .bcc-icon {
      vertical-align: text-top;
    }
    .bundle-indicator {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
    }
    .bundle-icon {
      vertical-align: middle;
      transform: rotate(0);
      transition: transform 200ms linear;
      width: fit-content;
      .aula-icon {
        font-size: 0.5rem;
        vertical-align: middle;
      }
    }

    .thread-last-message {
      font-size: 14px;
      color: var(--color-grey-dim);
      max-height: 32px;
      display: flex;
      align-items: center;
      gap: 4px;
      height: 32px;
      line-height: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      overflow-wrap: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .draft-badge {
      display: inline-block;
      background-color: var(--color-alert);
      color: var(--color-white);
      text-transform: uppercase;
      padding: 0 4px;
      font-size: 12px;
      border-radius: 4px;
      margin-top: auto;
    }
  }

  .thread-metadata {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: auto;

    .related-avatars {
      --size: 8px;
      --outline-width: 1px;

      width: fit-content;

      /deep/.related-profile-avatar {
        --width: 24px;
        --height: 24px;
        --font-size: 0.5rem;

        font-weight: normal;
      }
    }
  }

  .sensitive-icon {
    align-self: center;
    color: var(--color-alert);
  }
}













































































































.unsubscribe-container {
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    117.36deg,
    var(--color-primary-light) 0%,
    var(--color-primary-dark-guardian) 100%,
    #02487a 100%,
    #192a79 100%,
    var(--color-primary-light) 100%
  );
  color: var(--color-white);
  font-size: 18px;
}

.separator {
  width: 100%;
  flex-basis: 100%;
}

.footer {
  align-self: end;
  margin-bottom: 15px;
  text-align: center;

  a,
  button {
    display: inline-block;
    font-size: 18px;
    color: var(--color-white);
    margin-left: 15px;
    margin-right: 15px;
  }
}

.aula-logo {
  text-align: center;
  font-size: 68px;
}

.go-to-aula {
  text-decoration: underline;
  color: var(--color-white);

  &:hover {
    color: var(--color-white);
  }
}

.card-wrapper {
  --icon-font-size: 40px;
  min-height: Min(230px, 100vh);
  display: flex;
  justify-content: center;
  align-items: center;
}



























































































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.unstyled-button {
  border: none;
  padding: 5px;
  background: none;
  color: $color-white;
}

.button-text {
  padding: 10px;
}

.handle-more-subscriptions-mobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 115px;
  background-color: $color-primary-base;
  z-index: 1050;

  .theme-guardian & {
    background-color: $color-primary-dark-guardian;
  }
  .theme-employee & {
    background-color: $color-primary-dark-employee;
  }
  .theme-child & {
    background-color: $color-primary-dark-child;
  }
  .text {
    width: 100%;
    color: $color-white;
    line-height: 30px;
    font-size: 18px;
    margin-left: 23px;
    margin-top: 10px;
    font-weight: bold;
  }
  .handle-buttons {
    margin-left: 20px;
    margin-top: 30px;
  }
  .close-icon {
    position: absolute;
    right: 5%;
    color: $color-white;
    transform: rotate(45deg);
  }
  .colored {
    color: $color_alert;
  }
}

.notification-settings {
  background-color: $color-grey-light;

  .notification-settings-header-container {
    position: relative;
    margin-bottom: 60px;

    @include breakpoint-lg() {
      margin-bottom: 20px;
    }

    min-height: 50px;

    .notification-settings-header {
      display: flex;

      .notification-settings-title {
        flex-grow: 1;
      }
    }

    button {
      height: 50px;
    }

    /deep/ .notification-settings-header-mobile {
      position: fixed;
      top: 0;
      left: 0;
      z-index: $aula-modal-open-z-index;
      width: 100%;

      .notification-settings-view-mode-header {
        background-color: $color-primary-darker;
        color: $color-white;

        .text-key {
          font-weight: bold !important;
          font-size: 15px;
        }

        .right-text {
          font-size: 13px;
          text-transform: none;
        }
      }

      .notification-settings-edit-mode-header {
        background-color: $color-grey-light;
        color: $color-primary-darker;
        height: 59px;

        .text-key {
          font-weight: bold !important;
          font-size: 15px;
        }

        .right-text {
          font-weight: bold;

          .theme-guardian &,
          .theme-child & {
            color: $color-primary-dark-guardian;
          }

          .theme-employee & {
            color: $color-primary-dark-employee;
          }
        }

        .right-text,
        .left-text {
          font-size: 13px;
        }
      }
    }

    .notification-settings-body-mobile {
      background-color: $color-white;
      margin-top: -1px;

      &.view-mode {
        pointer-events: none;
      }

      .strong-label {
        height: 40px;
        width: 100%;
        text-transform: uppercase;
        font-weight: bold;
        padding: 10px 20px;
        font-size: 13px;
        background-color: $color-grey-base;
        color: $color-primary-darker;
      }

      .widget-settings .widget-setting:last-child label.item {
        border-bottom: none;
      }

      .item {
        margin: auto 20px;
        min-height: 55px;
        width: calc(100% - 40px);
        border-bottom: 1px $color-grey-dark solid;
        font-size: 14px;
        color: $color-primary-darker;
        padding: 10px 0;
        font-weight: normal;

        .icon-border {
          display: inline-block;
          height: 35px;
          width: 35px;
          border-radius: 50%;
          background-color: $color-grey-base;
          padding: 10px;
        }

        .item-label {
          display: inline-block;
          padding-top: 5px;
        }

        .item-child {
          min-height: 55px;
          padding: 10px 0;
          position: relative;

          .el-date-editor {
            position: absolute;
            top: -10px;
            right: 0;
            width: 110px;
          }

          .select-days {
            padding: 0 10px;
            background-color: $color-grey-light;
          }

          .small-icon {
            font-size: 12px;
          }
        }

        .item-time-picker {
          margin-right: -20px;
        }

        span {
          cursor: pointer;
        }

        .view-mode-mobile {
          display: inline-block;
          padding-top: 5px;
        }
      }

      .upper-case {
        text-transform: uppercase;
      }
    }
  }

  .notification-settings-body {
    display: grid;
    grid-template-columns: 280px 220px 220px 1fr;
    align-items: center;
    row-gap: 4px;

    .settings-label {
      grid-column-start: 1;
      background-color: var(--color-grey-base);
      padding: 14px;
    }
    .notification-option {
      background-color: var(--color-grey-base);
      display: flex;
      align-items: center;
      height: 100%;
    }
    .single-option {
      grid-column: span 3;
    }
    .second-option {
      grid-column: span 2;
    }
  }
  .notification-setting-areas {
    display: grid;
    grid-template-columns: 280px 1fr;
    align-items: center;
    row-gap: 4px;
    border: 1px solid var(--color-grey-base);

    .settings-label {
      grid-column: span 2;
      background-color: var(--color-grey-base);
      padding: 14px;
    }
    .settings-content {
      grid-column: 2;
    }
    .custom-checkbox {
      width: 220px;
    }
    .custom-radio {
      width: 204px;
    }
  }

  .image-link {
    width: 150px;
  }

  .btn-link {
    cursor: pointer;
  }

  .settings-label {
    color: $color-primary-darker;
    font-weight: bold;
    font-size: 14px;
  }

  /deep/ .custom-control-label {
    font-weight: normal;
  }

  /deep/ label {
    text-transform: none;
  }

  .content-bar {
    background-color: $color-grey-base;
    min-height: 50px;
    padding: 10px 3px 6px 19px;
    margin-bottom: 5px;

    .settings-label {
      display: inline-block;
      width: 250px;
    }

    /deep/ .custom-control-label {
      padding-top: 0px;
      line-height: 24px;
    }
  }

  .content-box {
    border: 1px solid $color-grey-base;
  }

  .box-padding {
    min-height: 50px;
    padding-left: 15px;
    padding-right: 15px;

    @include breakpoint-sm-up() {
      padding-left: 270px;
      padding-right: 20px;
    }
  }

  table {
    width: 230px;
    table-layout: auto;
    border-spacing: 0 16px;
    border-collapse: separate;
  }

  .inline-block {
    display: inline-block;
  }

  .form-select {
    width: 240px;
  }

  h1 {
    @include breakpoint-xs-down() {
      font-size: 23px;
    }
  }

  .m-t-20 {
    margin-top: 20px;
  }

  /deep/ .custom-control-input:focus ~ .custom-control-label::before {
    outline: var(--color-primary-light) solid 0.25rem;
    box-shadow: none;
  }

  /deep/ .custom-checkbox:has(.custom-control-input:focus-visible) {
    outline: var(--color-primary-dark) solid 0.125rem;
    outline-offset: 5px;
    box-shadow: none;
  }
}

.theme-guardian {
  .notification-settings .notification-settings-header-container .notification-settings-body-mobile {
    &.edit-mode {
      .strong-label {
        background-color: $color-primary-dark-guardian;
        color: $color-white;
      }
    }
  }
}

.theme-child {
  .notification-settings .notification-settings-header-container .notification-settings-body-mobile {
    &.edit-mode {
      .strong-label {
        background-color: $color-primary-dark-child;
        color: $color-white;
      }
    }
  }
}

.theme-employee {
  .notification-settings .notification-settings-header-container .notification-settings-body-mobile {
    &.edit-mode {
      .strong-label {
        background-color: $color-primary-dark-employee;
        color: $color-white;
      }
    }
  }
}

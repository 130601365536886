




















































.album-card {
  --thumbnail-background-color: var(--color-primary-base);
}
.album-card:hover {
  --thumbnail-height: 0;
  --description-height: 270px;

  .gallery-icon {
    color: var(--color-primary-darker);
    .album-text {
      display: inline;
    }
  }
  .more-information {
    display: block;
  }
}

.album-thumbnail {
  position: relative;
  height: 200px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(calc(100% / 3), 1fr));
  .album-icon {
    --font-size: 64px;
  }
}
.gallery-icon {
  position: absolute;
  z-index: 1;
  top: 20px;
  left: 10px;
  font-size: 14px;
  color: var(--color-white);

  .album-text {
    display: none;
    font-weight: bold;
  }
}
.album-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  height: 100%;

  .album-title {
    font-size: 1rem;
    font-weight: 900;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .more-information {
    display: none;
    font-size: 14px;
    text-align: center;
    color: var(--color-dark-grey);
  }
}
.separated-line {
  width: 40px;
  border: 1px solid var(--color-grey-dark);
}

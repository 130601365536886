













.thread-compact-container {
  display: flex;
  gap: var(--thread-item-gap, 2px);

  .thread-session {
    padding: 0.5rem;
    background-color: var(--thread-background-color, var(--color-base));
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    &.recipients {
      flex: 1;
    }
    &.name {
      flex: 1;
    }
    &.date {
      width: 120px;
    }
  }
}

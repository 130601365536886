




















































































































































.message-recipients-popover {
  height: 100%;

  .message-recipients-target {
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-width: 128px;
    transition: box-shadow 300ms ease-in;
    z-index: 1;

    .message-recipients-button {
      display: flex;
      justify-content: center;
      min-height: 24px;

      span {
        color: var(--color-primary-darker);
        font-weight: 400;
      }

      .icon-container {
        background-color: transparent;
      }

      .aula-icon {
        --font-size: 6px;
        color: var(--color-primary-darker);
      }
    }

    /deep/.avatar-group .avatar {
      &:hover {
        cursor: pointer;
      }
    }

    .message-recipient-profile-pictures {
      width: 100%;
      display: flex;
      justify-content: center;

      /deep/.thread-detail-avatars:not([data-extra='0']) > .avatar:last-child::after {
        font-size: 14px;
      }
    }
  }
}

.member-type {
  font-size: 16px;
  margin-top: 16px;

  label {
    color: var(--color-primary-darker);
    text-transform: none;
    font-weight: 700;
  }

  &:first-child {
    margin-top: 0;
  }

  ul {
    list-style-type: none;
    padding: 0;
    font-weight: 400;
  }
}





































































































































































.message-inbox {
  max-width: 500px;
  /deep/.expand-button,
  /deep/.add-folder-button,
  /deep/.sub-folders .folder-actions {
    display: none;
  }
}





























.print-button {
  font-size: var(--font-size, 16px);
}

.print-icon {
  font-size: calc(8px + var(--font-size, 16px));
  margin-right: 5px;
}

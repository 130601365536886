// Aula logo animation
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 1;
}

.horizontal-expand-enter-active,
.horizontal-expand-leave-active {
  transition-property: width, min-width, opacity, padding-left, padding-right;
  transition-duration: 300ms;
  transition-timing-function: var(--ease-in-out);
}

.horizontal-expand-enter,
.horizontal-expand-leave-to {
  width: 0 !important;
  min-width: 0 !important;
  opacity: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.slide-fade-enter-active {
  transition-property: opacity, transform;
  transition-duration: 300ms;
  transition-timing-function: var(--ease-in-out);
}

.slide-fade-leave-active {
  transition-property: opacity, transform;
  transition-duration: 300ms;
  transition-timing-function: var(--ease-in-out);
}

.slide-fade-enter
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-100%);
  opacity: 1;
}

.slide-fade-leave-to {
  transform: translateY(-100%);
  opacity: 1;
}

.leave-active-hide {
  transition: all 0.6s ease-out;
}

.leave-to-hide {
  opacity: 1;
}

.slide-only-enter-active,
.slide-only-leave-active,
.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 300ms var(--ease-in-out);
}

.slide-mobile-enter-active {
  animation: slide-in 0.6s;
}

.slide-mobile-leave-active {
  animation: slide-out 0.6s;
}

.slide-only-enter,
.slide-only-leave-to {
  transform: translateX(103%);
}

.slide-up-enter,
.slide-up-leave-to {
  transform: translateY(103%);
}

// Toggle transition
.toggle-enter-active,
.toggle-leave-active {
  transition-property: opacity, max-height;
  transition-duration: 200ms;
  max-height: 500px;
}
.toggle-enter,
.toggle-leave-active {
  opacity: 0;
  max-height: 0;
}
.toggle-leave {
  max-height: 500px;
}

.list-move {
  transition: all 200ms ease-in-out 50ms;
}

.list-enter-active {
  transition: all 200ms ease-out;
}

.list-leave-active {
  transition: all 200ms ease-in;
}

.list-enter,
.list-leave-to {
  opacity: 0;
}

@keyframes slide-in {
  from {
    transform: translateX(92%);
  }

  to {
    transform: translateX(0%);
  }
}

@keyframes slide-out {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(103%);
  }
}












































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/core/animations.scss';

.group-profile-icons {
  display: block;
  position: absolute;
  background-color: $color-primary-light-employee;
  width: 100vw;
  height: 100dvh;

  top: 0px;
  .icon-Aula_close {
    position: absolute;
    right: 20px;
    top: 15px;
    color: $color-white;
    z-index: 1000;
  }
  .item {
    display: block;
    width: 100%;
    height: 50%;
    &.profile {
      background-color: $color-primary-base-employee;
    }
    .group-profile {
      position: relative;
      top: calc(50% - 55px);
      text-align: center;
      .group-icon {
        display: block;
        margin: 0 auto;
        border-radius: 50%;
        width: 90px;
        height: 90px;
        max-height: 30vh;
        max-width: 30vh;
        font-size: 30px;
        background-color: $color-primary-dark-employee;
        i {
          display: block;
          padding-top: 25px;
          color: $color-white;
        }
        img {
          width: 60px;
          padding-top: 18px;
        }
      }
      .user {
        width: 80px;
        margin: 0 auto;
        /deep/ .user-icon {
          width: 80px;
          height: 80px;
          .user-img {
            max-width: 80px;
            max-height: 80px;
          }
          .shortname {
            font-size: 1.8em;
          }
        }
      }
      .item-title {
        display: block;
        width: 100%;
        text-align: center;
        font-size: 1.2em;
        margin-top: 12px;
        color: $color-white;
      }
    }
  }
  .mobile-header {
    margin: auto;
  }
}

.header-dropdown-menu {
  height: 100vh;
  z-index: $header-dropdown-menu-mobile-z-index;
  top: 0 !important;
}

//Theme styling
.theme-admin {
  .group-profile-icons {
    background: $color-primary-light-admin;
    .profile {
      background-color: $color-primary-base-admin;
    }
    .group-profile {
      .group-icon {
        background-color: $color-primary-dark-admin;
      }
    }
  }
}

.theme-guardian {
  .group-profile-icons {
    background: $color-primary-light-guardian;
    .profile {
      background-color: $color-primary-base-guardian;
    }
    .group-profile {
      .group-icon {
        background-color: $color-primary-dark-guardian;
      }
    }
  }
}

.theme-employee {
  .group-profile-icons {
    background: $color-primary-light-employee;
    .profile {
      background-color: $color-primary-base-employee;
    }
    .group-profile {
      .group-icon {
        background-color: $color-primary-dark-employee;
      }
    }
  }
}

.theme-child {
  .group-profile-icons {
    background: $color-primary-light-child;
    .profile {
      background-color: $color-primary-base-child;
    }
    .group-profile {
      .group-icon {
        background-color: $color-primary-dark-child;
      }
    }
  }
}




































































































































































































































































































































































































































@import '../../../shared/assets/scss/core/breakpoints.scss';
.early-student .aula-menu .menu-item {
  --aula-badge-box-shadow: none;
}

.hidden {
  display: none !important;
}

.aula-menu {
  .early-student & {
    background: linear-gradient(180deg, #549ec7 0%, #45b7c1 100%);
  }

  .menu-link {
    /deep/ .menu-item {
      .early-student & {
        img.menu-icon {
          transition: transform 0.1s ease-in-out;

          @include breakpoint-lg-down() {
            --menu-icon-size: 22px;
          }
        }

        @include breakpoint-lg-down() {
          padding-bottom: 1px;
          justify-content: flex-end;
        }
      }
    }

    /deep/ .menu-item:hover {
      .early-student & {
        background-color: transparent !important;
        img.menu-icon {
          transform: scale(1.2);
        }
      }
    }
  }

  .menu-link:hover {
    .early-student & {
      background-color: transparent;
    }
  }

  .menu-link.router-link-active {
    --aula-badge-box-shadow-color: var(--color-primary-darker);

    /deep/ .menu-item {
      .early-student & {
        background-color: rgba(0, 0, 0, 0.3) !important;
      }
    }
  }

  .menu-item.more {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    font-size: 20px;

    .early-student & {
      background-color: transparent;
    }

    &:hover,
    &:focus-visible {
      .early-student & {
        background-color: transparent !important;
      }
    }

    .icon-Aula_elipses {
      .early-student & {
        color: var(--color-white);
      }
    }
  }
}


















































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/mixins.scss';

.subscription-touch-actions {
  display: grid;
  grid-template-rows: 1fr;

  &[data-slots='1'] {
    grid-template-columns: 1fr;
  }

  &[data-slots='3'] {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .touch-action {
    border-radius: 0;

    &:first-child {
      background-color: var(--color-grey-dunkel);
    }

    &:nth-child(2) {
      background-color: var(--color-grey-dark);
    }

    &:last-child {
      background-color: var(--color-grey-base);
    }

    @include touch-hover {
      color: var(--color-primary-darker)
    }
  }
}

.modal-body {
  .thread-title {
    font-weight: bold;
    padding-bottom: 8px;
    margin-bottom: 4px;
    border-bottom: 1px solid var(--color-grey-base);
  }

  .more-option-group {
    border-radius: 8px;
    overflow: hidden;
  }

  .more-option-button {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 8px;
    padding: 8px 0;
    border-radius: 0;
  }
}




















































.aula-list-item {
  padding: 14px 24px;
  border-bottom-width: 1px;
}

.aula-list {
  padding: 0;
}

.module-item {
  --border-bottom-color: var(--color-grey-darker);
}
















































































































































































.aula-widget-placeholder {
  padding: 5px;

  /deep/ .aula-widget {
    padding: 0;
  }
}

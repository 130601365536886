




















@import '../../../shared/assets/scss/core/breakpoints.scss';

.onboarding-step5 {
  height: 100%;
  overflow-y: auto;
  .step-title {
    border-bottom: 1px solid var(--color-primary-dark);
    padding-bottom: 8px;
  }

  @include breakpoint-lg-down() {
    .step-title {
      display: none;
    }
  }
}
/deep/.notification-settings {
  padding: 0;
  background-color: unset;

  .page-title,
  #notification-clear-all-module,
  #notification-setting-edit-button {
    display: none;
  }

  .notification-settings-body {
    grid-template-columns: 200px 220px 200px 1fr;
  }
  .notification-setting-areas {
    grid-template-columns: 200px 1fr;
    .vue__time-picker input {
      --background-color: var(--color-grey-light);
    }
  }

  @include breakpoint-lg-down() {
    .notification-settings-body {
      grid-template-columns: 100%;
      gap: 0;
      border: 1px solid var(--color-grey-base);

      .settings-label {
        padding: 12px;
      }
      .notification-option {
        grid-column-start: 1;
        padding: 8px 20px 8px 40px;
        background-color: var(--color-white);
      }
    }

    .notification-setting-areas {
      grid-template-columns: 100%;
      .settings-content {
        grid-column-start: 1;
        padding: 0 12px;
      }
    }
  }
}

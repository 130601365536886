









































































































































































































































































































































































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
.contacts {
  @import '../../../shared/assets/scss/components/contacts/_contacts.scss';
}





































































.draft {
  .draft-hidden {
    visibility: hidden;
  }
}

.thread-action {
  padding: 4px;
  display: grid;
  place-items: center;
  border-radius: 4px;
}

button i {
  font-size: var(--thread-actions-icon-size, 1rem);
  vertical-align: middle;
  color: var(--color-primary-base);
  &.icon-Aula_star_solid {
    color: var(--color-alert);
  }
}

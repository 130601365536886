





























































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.privacy-policy-container {
  a {
    text-decoration: underline;
  }
}
























































































































































































































































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/core/animations.scss';
.aula-search-input {
  @include breakpoint-lg-down() {
    --input-min-height: 40px;
    width: calc(100% - 45px);
  }
}
.aula-search {
  border-right: 1px solid $color-white;
  @include breakpoint-lg-down() {
    border-left: 1px solid $color-white;
  }
}
img.menu-icon {
  width: var(--default-menu-icon-size);
}

.small-text {
  text-transform: uppercase;
  .early-student & {
    color: #fff !important;
    text-transform: capitalize;
    font-family: Baloo;
    font-weight: normal;
    font-size: 15px !important;
  }
}

@include breakpoint-lg() {
  .header-dropdown-menu-search .header-dropdown-close {
    color: inherit;
  }
}

.header-dropdown-search-field {
  height: 58px;
  display: flex;
  align-items: center;
  background-color: var(--color-primary-dark);

  @include breakpoint-lg() {
    padding-left: 0;
    padding-top: 0;
    background-color: transparent;
  }
}

.header-dropdown-title {
  display: none;
  width: 50%;
  @include breakpoint-lg() {
    display: block;
    padding-top: 30px;
  }
}

.search-filter {
  font-size: 13px;
  margin: 50px 0 13px 0;
  display: none;
  @include breakpoint-lg() {
    display: block;
  }
}

.header-dropdown-menu-search {
  .icon-Aula_close {
    cursor: pointer;
  }
}

.filter-dropdown {
  .filter-dropdown-item {
    display: none;
    @include breakpoint-lg() {
      display: block;
    }
  }
  &.active {
    br {
      display: none;
      @include breakpoint-lg() {
        display: block;
      }
    }
    background-color: $color-white;
    @include breakpoint-lg-down() {
      background-color: $color-grey-dark;
    }
    @include breakpoint-lg() {
      height: 100%;
    }
  }
}




































































.system-message {
  padding: 0 64px 20px 64px;
  border-bottom: 1px solid var(--color-read-message);
  &[data-expanded] {
    .expand-icon {
      display: inline-block;
      transform: rotate(180deg);
    }
  }
  .system-message-header {
    font-weight: bold;
  }
  .message-forwarded-time {
    font-size: 15px;
  }
}

.forward {
  border-bottom: 1px solid var(--color-read-message);
}

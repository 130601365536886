










.box {
  padding-top: 40px;
  padding-bottom: 40px;
  padding-right: 40px;
  padding-left: 40px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-white);
}










































[data-sidebar='sidebar'] {
  background-color: var(--sidebar-background);
  border-radius: 4px;
  height: 100%;
}

.threads {
  i.icon-Aula_lock {
    color: var(--color-alert);
  }

  .aula-spinner {
    width: 80px;
    height: 80px;
  }

  .bundle-container {
    display: block;

    .bundle-spinner {
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .thread {
      .marked {
        left: 130px;
        width: 20px;
      }
      .swipe-buttons {
        height: $subscription-container-height-small;
      }
    }
  }

  .thread {
    .thread-header {
      display: flex;
      align-items: center;
      width: calc(100% - 30px);

      .hover-buttons {
        margin-left: 5px;
      }
    }

    .last-message-sent-time {
      white-space: nowrap;
    }

    margin-bottom: 2px;
    cursor: pointer;

    .router-link-active {
      .thread-item-mobile {
        border-right: none;
      }

      .card {
        background-color: $color-white;
      }
    }

    &:hover {
      .card {
        background-color: $color-grey-dark !important;
      }

      .overlay-show {
        height: 100%;
      }

      .overlay-hide {
        opacity: 0;
      }

      .custom-checkbox {
        visibility: visible;
      }

      .hover-buttons button {
        display: inline-block;
      }
    }

    &.selected,
    &.multiple-selected {
      .custom-checkbox {
        visibility: visible;
      }
    }

    &.selected {
      .card {
        background-color: $color-grey-base;
      }
    }
  }

  .thread-item-mobile {
    height: 80px;
  }

  .multiple-selected {
    .thread-item-mobile {
      .custom-checkbox {
        visibility: visible;
        position: absolute;
        left: 8px;
        top: 30px;
      }
    }
  }

  .marked,
  .colored {
    color: $color-alert !important;
  }

  .marked {
    font-size: 10px;
    position: absolute;
    top: 7px;
    left: 115px;
  }

  .hover-buttons {
    height: 21px;

    .colored {
      color: $color-alert;
    }

    button {
      color: $color-primary-darker !important;
      width: 20px;
      margin-right: 2px;

      &:hover {
        text-decoration: none;
      }

      &:not(.action-button-marked) {
        display: none;
      }

      &.btn-sm i {
        margin: 0;
      }
    }
  }

  .list-of-participants {
    display: flex;
    align-items: center;
    min-height: 12px;
    margin-bottom: 0.25rem;
    line-height: 12px;
    font-size: 12px;
    width: calc(100% - 50px);

    .participant-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .list-of-recipients {
    display: inline;
  }

  .thread-subject {
    font-weight: bold;
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    line-height: 17px;
    white-space: nowrap;
    width: 100%;
    position: relative;
    top: -2px;
  }

  .bundle-items-total,
  .thread-last-text,
  .subscription-last-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 15px;
    max-height: 36px;
    -webkit-line-clamp: 1;

    /*! autoprefixer: off */
    -webkit-box-orient: vertical;
    font-size: 13px;
  }

  .list-of-children-images {
    width: 90px;
    position: relative;
    display: inline;
  }

  .relevant-child {
    position: relative;
    width: 38px;
    height: 38px;
    border-radius: 38px;
    margin-left: -20px;
  }

  .participant-names {
    display: inline;
  }

  .draft > button:not(.delete) {
    visibility: hidden;
  }

  .subscription-last-text,
  .thread-last-text {
    .subscription-draft-label-prefix {
      background-color: $color-alert;
      margin-right: 3px;
      margin-top: 3px;
      float: none !important;
    }
  }

  .swipe-buttons {
    $swipe-button-width: 51;

    position: absolute;
    height: $subscription-container-height;
    width: $swipe-button-width * 3 + px;
    right: -$swipe-button-width * 3 + px;
    border: none;
    border-radius: 0;
    background-color: white;
    display: flex;
    flex-grow: 1;
    flex-basis: 0;

    &.is-draft {
      width: $swipe-button-width + px;
      right: -$swipe-button-width + px;
    }

    .button-text {
      color: $color-primary-darker;
    }

    button {
      height: 100%;
      border: none;
      font-size: 16px;
      padding: 10px 0 0 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    i {
      display: flex;
      height: 1rem;
      align-items: center;
      font-size: 18px;
      color: $color-primary-darker;
    }

    .swipe-button {
      width: $swipe-button-width + px;
    }

    .swipe-button-slot-1 {
      background-color: $color-grey-dunkel;
    }

    .swipe-button-slot-2 {
      background-color: $color-grey-dark;
    }

    .swipe-button-slot-3 {
      .swipe-dropdown {
        width: 100%;
        height: 100%;
        .dropdown-item {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          &:hover {
            background-color: $color-dropdown-focus;
          }
        }
      }
      button.dropdown-toggle {
        border-radius: 0;
        background-color: $color-grey-base;
        i {
          color: $color-primary-darker;
        }
      }
    }
  }

  .touch-area {
    left: 0;
    position: relative;
    width: 100%;
    z-index: 10;
  }

  .modal-mobile {
    background-color: $color-primary-light;
    border-radius: 0;

    .modal-header-text {
      height: 22px;
      color: $color-white;
      font-family: Lato;
      font-size: 18px;
      font-weight: bold;
      line-height: 22px;
      padding: 19px;
    }
  }

  .button-text {
    font-size: 12px;
    color: $color-white;
    width: 100%;
  }

  .check-close {
    font-size: 30px;
    float: right;
  }

  .list-of-institution-images,
  .list-of-children-images {
    position: absolute;
    top: 10px;
    right: -45px;

    > div {
      position: absolute;
      z-index: 1;

      @for $i from 2 through 10 {
        &:nth-child(#{$i}) {
          right: (12 * ($i - 2)) + 71px;
          z-index: $i;
        }
      }
    }

    .user-icon {
      border: solid 1px $color-white;
    }
  }
}

.addRecipientsModal {
  @include breakpoint-lg() {
    .modal-aula {
      width: 600px;
      max-width: 100%;
    }

    .modal-body {
      min-height: 300px !important;
    }
  }

  @include breakpoint-sm-down() {
    .modal-aula {
      --modal-inner-input-background-color: var(--color-grey-light);
    }
  }
}

.bundle-icon {
  visibility: hidden;
  font-size: 0.5rem;
  vertical-align: middle;
  transform: rotate(0);
  transition: transform 200ms linear;
}
.bundle-item-total {
  font-size: 0.8rem;
  display: none;
}
.latest-message {
  font-size: 0.8rem;
}
.bundle {
  .bundle-icon {
    visibility: visible;
  }
  .bundle-item-total {
    display: initial;
  }
  .latest-message {
    display: none;
  }
  &.bundle-open .bundle-icon {
    transform: rotate(180deg);
  }
}






































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/presence/_presence.scss';
.mobile-aula-spinner {
  position: absolute;
  width: 60px;
  height: 60px;
  top: -15px !important;
  left: 5px;
}
.submit-loading {
  position: absolute;
  width: 80px;
  height: 80px;
}

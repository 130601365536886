












































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.threads-container {
  position: relative;
  overflow-anchor: none;

  .subscription-header-container {
    background-color: var(--color-grey-light);
    z-index: 998;
    transition: 500ms max-height ease-in;

    /deep/.action-buttons-container {
      .buttons {
        align-items: flex-start;
        padding-top: 8px;

        .toggle-button {
          height: 24px;
        }
      }
    }

    &[data-collapsed] {
      position: sticky;
      top: 0;
      max-height: 60px;

      @include breakpoint-lg-down() {
        max-height: unset;
      }

      /deep/.action-buttons-container .buttons {
        align-items: center;
        padding-top: 0;
      }

      /deep/.subscription-title-container {
        white-space: nowrap;
        overflow: hidden;

        .subscription-title {
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .subscription-creator {
          display: none;
        }
      }

      /deep/ .message-recipients-popover .message-recipients-button {
        display: none;
      }
    }
  }
}

.compact-header {
  display: none;
  font-size: 14px;
  position: absolute;
  left: 80px;
  top: -35px;
  @include breakpoint-xl {
    left: 100px;
    top: -35px;
  }
  strong {
    margin-right: 5px;
  }
  .icon {
    font-size: 6px;
    position: relative;
    left: 7px;
    top: -1px;
    cursor: pointer;
  }
}

.shown-subscription-container {
  width: 100%;
}

.load-more {
  text-align: center;

  div {
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    vertical-align: middle;
    font-size: 13px;
    padding: 15px;
    background-color: $color-grey-light;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(193, 192, 192, 0.5);
    z-index: 1;
    left: 58%;
    cursor: pointer;
  }
}

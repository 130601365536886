





























































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/elements/modal.scss';

.colored {
  color: var(--color-alert);
}

.action-buttons-container {
  display: flex;
  gap: 8px;
  flex-flow: nowrap;
  height: 100%;

  .buttons {
    flex-wrap: nowrap;
    gap: 4px;
    height: 100%;
  }

  .icon-container {
    background-color: transparent;

    .aula-icon {
      --font-size: 18px;
    }
  }
}

.more-actions-dropdown {
  /deep/ &.show .dropdown-toggle.dropdown-toggle-no-caret {
    z-index: 1001;
    color: var(--color-primary-base-employee);
  }

  /deep/ &.show .dropdown-menu {
    max-height: calc(100vh - 200px);
    overflow-y: auto;

    .dropdown-item {
      display: flex;
      flex-direction: row;
      font-size: 14px;

      .icon-container {
        background-color: transparent;
      }

      span {
        padding-left: 8px;
        align-self: center;
      }
    }
  }
}

/deep/ .dropdown-menu.action-menu {
  margin-top: -8px;
  margin-right: -28px;
  padding-top: 48px;
  padding-bottom: 24px;
  min-width: 300px;
}

/deep/ .action-button {
  padding: 2px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 150ms ease-in;

  &:hover {
    background-color: var(--color-white);
  }
}

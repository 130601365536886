

























































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.browser-link {
  color: #2091a2;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }

  .icon-Aula_forward-arrow {
    font-size: 10px;
  }
}

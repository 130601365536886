





























































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.recipients {
  margin-bottom: 6px;
}

.popover-container {
  color: var(--color-darkblue);
}

.open-button {
  color: var(--color-help-text);
  text-decoration: none;
}

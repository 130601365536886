





































































@import '../../../shared/assets/scss/core/animations.scss';
.subscription-container-variables {
  --thread-item-gap: 2px;
}
.subscription-container {
  margin-top: var(--thread-item-gap);
}

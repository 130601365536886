





























.el-date-picker.el-date-editor.el-input {
  width: 100%;
}











































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/gallery/toolbar.scss';

.navbar-nav {
  display: flex;
  gap: 4px;
}
.tool-link {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px;
  border-radius: 4px;
  height: 38px;
  transition: background-color 150ms ease-in;

  &:hover {
    background-color: var(--color-grey-light);
  }
}

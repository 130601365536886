




























































































































































































































































@import '../../../shared/assets/scss/core/_breakpoints.scss';

.search-result-item .card-body {
  @include breakpoint-sm-down() {
    --card-body-flex-direction: column;
  }
}

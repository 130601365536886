



































































































































































































































































































.group-search {
  position: absolute;
  top: 0px;
  right: 0px;
}

.application-actions {
  float: right;
  position: relative;

  .btn-link {
    text-decoration: underline;
  }
}










































































































































































































































































































































































































































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/messages/_subscriptions.scss';

.subscription {
  --translateX: var(--drag-distance, 0);
  --touch-actions-width: calc(var(--drag-distance, 0) * -1);
  position: relative;
  @include breakpoint-lg-down() {
    transition: transform 150ms linear;
    transform: none;
  }

  &[data-dragging] {
    transform: translateX(var(--translateX));
    box-shadow: none;
  }
  &:not([data-dragging]) .subscription-touch-actions {
    width: 150px;
  }
  .subscription-touch-actions {
    position: absolute;
    left: 100%;
    top: 0;
    height: 100%;
    width: var(--touch-actions-width);
    transition: width 150ms linear;
  }
}

@import './breakpoints.scss';

* {
  scrollbar-width: auto;

  &::-webkit-scrollbar-track {
    background-color: $color-grey-light;
  }

  &::-webkit-scrollbar {
    width: var(--scrollbar-width);
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-grey-darker;
    border: 3px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-button:start {
    background-color: $color-white;
    background-image: url(/static/angle-up.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &::-webkit-scrollbar-button:end {
    background-color: $color-white;
    background-image: url(/static/angle-down.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &::-webkit-scrollbar-button:horizontal:start {
    background-image: url(/static/angle-left.png);
  }

  &::-webkit-scrollbar-button:horizontal:end {
    background-image: url(/static/angle-right.png);
  }

  @include breakpoint-lg-down() {
    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  &.hide-scrollbar,
  .notice-boards & {
    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
}

















































.profile-filter-group-home {
  .checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}

















































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
.aula-documents-container {
  .document-title {
    outline: none;
  }
  .linkBox {
    background-color: $color-grey-base;
    margin: 4px 2px;
    padding-top: 20px;
    @media only screen and (max-width: 780px) {
      margin: 10px auto;
      max-width: 330px;
    }
    cursor: pointer;
    .icons {
      width: 100px;
      margin: 0 auto;
      position: relative;
      .icon-Aula_folder {
        color: $color-grey-darker;
        font-size: 70px;
      }
      .icon-Aula_star_solid,
      .icon-Aula_lock,
      .icon-Aula_cloud,
      .icon-googledrive,
      .icon-onedrive {
        color: #fff;
        width: 46px;
        text-align: center;
        height: 46px;
        line-height: 36px;
        display: inline-block;
        border-radius: 50%;
        border: solid 4px $color-grey-base;
        position: absolute;
        right: 5px;
        bottom: -5px;
        font-size: 18px;
      }
    }
    h2 {
      padding-top: 20px;
      font-size: 16px;
      font-weight: 900;
      display: block;
    }
    .text {
      margin: 0 auto;
      padding: 0 5px;
      min-height: 66px;
      text-align: left;
    }
    .link {
      margin-top: 20px;
      color: #fff;
      padding: 0 30px;
      height: 62px;
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .icon-Aula_forward-arrow {
      margin-top: 3px;
    }
  }
}

.aula-documents-container {
  .linkBox {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .icons {
      .icon-Aula_lock,
      .icon-Aula_star_solid,
      .icon-Aula_cloud,
      .icon-googledrive,
      .icon-onedrive {
        .theme-guardian & {
          background-color: $color-primary-dark-guardian;
        }
        .theme-employee & {
          background-color: $color-primary-dark-employee;
        }
        .theme-child & {
          background-color: $color-primary-dark-child;
        }
      }
    }
    .link {
      .theme-guardian & {
        background-color: $color-primary-dark-guardian;
      }
      .theme-employee & {
        background-color: $color-primary-dark-employee;
      }
      .theme-child & {
        background-color: $color-primary-dark-child;
      }
    }
    &:hover {
      .link {
        .theme-guardian & {
          background-color: $color-primary-base-guardian;
        }
        .theme-employee & {
          background-color: $color-primary-base-employee;
        }
        .theme-child & {
          background-color: $color-primary-base-child;
        }
      }
      .icons {
        .icon-Aula_lock,
        .icon-Aula_star_solid,
        .icon-Aula_cloud {
          .theme-guardian & {
            background-color: $color-primary-base-guardian;
          }
          .theme-employee & {
            background-color: $color-primary-base-employee;
          }
          .theme-child & {
            background-color: $color-primary-base-child;
          }
        }
      }
    }
    /deep/ .badge-notification {
      left: 0px;
      top: 50px;
    }
  }
}

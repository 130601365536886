
































.post-time {
  font-size: 0.75rem;
}





























.content {
  text-align: center;
}

.icon {
  font-size: var(--icon-font-size);
  margin-bottom: 15px;
  display: inline-block;
}

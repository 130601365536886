



































































.media-action-mobile-view {
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background: var(--color-grey-light);
  z-index: var(--modal-z-index);

  .media-action-navbar {
    padding: 10px 20px;
    border-bottom: 1px solid var(--color-grey-dunkel);
  }
}

.action-button {
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0 5px;
  margin: 0 5px;

  .action-spinner {
    position: absolute;
    right: -40px;
    width: 50px;
    height: 50px;
  }
}

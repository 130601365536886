














































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/core/animations.scss';
@import '../../../shared/assets/scss/components/messages/_subscriptions.scss';

.bundle-container {
  &[data-with-primary] {
    --indicator-size: 2px;
    --indicator-border-radius: 8px;
    --indicator-width: 14px;
    --indicator-top: 18px;
    --indicator-left: 40px;
    --indicator-height: 20px;
    --connected-line-height: calc(100% + 30px);
    --connected-line-top: 60px;
    --primary-line-height: 64px;

    /deep/ .subscription {
      .thread-compact-item {
        --indicator-width: 16px;
        --indicator-top: 2px;
        --indicator-left: 64px;
        --connected-line-height: calc(100% + 8px);
        --connected-line-top: 20px;
        --primary-line-height: 32px;
      }

      &:first-child {
        :is(.thread-standard-item, .thread-compact-item)::after {
          content: '';
          position: absolute;
          z-index: 1;
          top: var(--connected-line-top);
          left: var(--indicator-left);
          height: var(--primary-line-height);
          border-left: var(--indicator-size) solid var(--color-grey-darker);
        }
        .thread-standard-item {
          height: 100px;
          padding: 12px 26px 12px 20px;
          gap: 20px;
          .thread-content {
            display: block;
            &.partial {
              display: none;
            }
          }
        }
      }

      &:not(:first-child) {
        :is(.thread-standard-item, .thread-compact-item)::before {
          content: '';
          position: absolute;
          top: var(--indicator-top);
          left: var(--indicator-left);
          width: 14px;
          height: var(--indicator-height);
          border-bottom-left-radius: var(--indicator-border-radius);
          border-left: var(--indicator-size) solid var(--color-grey-darker);
          border-bottom: var(--indicator-size) solid var(--color-grey-darker);
        }
        .thread-standard-item {
          height: 72px;
          padding: 10px 26px 10px 56px;
          .first-recipient {
            display: none;
          }
        }
        .thread-compact-item {
          .thread-session .recipient-name {
            padding-left: 26px;
          }
        }
      }
      &:not(:first-child):not(:last-child) {
        :is(.thread-standard-item, .thread-compact-item)::after {
          content: '';
          position: absolute;
          z-index: 1;
          top: 0;
          left: var(--indicator-left);
          height: var(--connected-line-height);
          border-left: var(--indicator-size) solid var(--color-grey-darker);
        }
      }
    }
  }

  &:not([data-with-primary]) {
    /deep/ .subscription .thread-standard-item {
      height: 72px;
      padding: 10px 26px 10px 36px;
      .thread-creator {
        display: none;
      }
    }

    /deep/.thread {
      :is(.thread-standard-item, .thread-compact-item) {
        // Connected line between sub threads
        &::before {
          content: '';
          z-index: 1;
          width: 1px;
          height: calc(100% + 4px);
          background-color: var(--color-grey-darkest);
          position: absolute;
          left: 35px;
          top: -1px;
          transform: translate(-50%, 0);
        }
        // Dot on the left of sub thread
        &::after {
          content: '';
          z-index: 1;
          width: 5px;
          height: 5px;
          background-color: var(--color-grey);
          position: absolute;
          left: 35px;
          border-radius: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &:first-child {
        :is(.thread-standard-item, .thread-compact-item)::before {
          height: calc(50% + 4px);
          top: 50%;
        }
      }
      &:last-child {
        :is(.thread-standard-item, .thread-compact-item)::before {
          height: calc(50% + 4px);
          bottom: 50%;
        }
      }
      &:only-child {
        :is(.thread-standard-item, .thread-compact-item)::before {
          display: none;
        }
      }

      :is(.thread-standard-item) {
        position: relative;
        // Connected line between sub threads
        &::before {
          left: 20px;
        }
        // Dot on the left of sub thread
        &::after {
          left: 20px;
        }
      }
      :is(.thread-compact-item) {
        position: relative;
        // Connected line between sub threads
        &::before {
          left: 55px;
        }
        // Dot on the left of sub thread
        &::after {
          left: 55px;
        }
      }
    }
  }
}

/deep/.dragging-subscription {
  opacity: 0.6 !important;
  box-shadow: var(--box-shadow-base);
  .subscription-touch-actions {
    display: none;
  }
}
/deep/.ghost-subscription.subscription {
  pointer-events: none;
  background-color: var(--color-white);
  border-radius: 4px;
  outline: 1px dashed var(--color-primary-darker);
  outline-offset: -1px;
  * {
    opacity: 0.6;
  }
}

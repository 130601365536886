





















































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.aula-calendar-aside-container {
  display: none;
  background-color: $color-grey-base;
  height: 100%;
  @include breakpoint-lg() {
    display: block;
  }
  & > div {
    font-size: 18px;
    padding: 28px 25px 0 25px;
  }
  /deep/ .quick-access-item {
    margin-top: 10px;
    height: 70px;
    line-height: 70px;
    padding: 0 25px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    font-size: 15px;
    background: $color-white;
    &:hover {
      background: $color-grey-light;
    }
  }
}


































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.search-input-group {
  --input-padding-right: 120px;

  .form-control {
    border-radius: 0;
  }

  .icon-Aula_search {
    font-size: 22px;
    cursor: pointer;
  }

  .filter-icon {
    font-size: 22px;
    cursor: pointer;
    margin-left: 10px;
    &.is-advanced-search-active {
      color: var(--color-primary-dark);
      font-weight: bold;
    }
  }

  .icon-Aula_close {
    font-size: 14px;
    margin-top: 5px;
    margin-right: 10px;
    cursor: pointer;
  }

  .addons {
    z-index: 100;
  }
}








































































.message-functionalities {
  --dropdown-max-width: 200px;
  /deep/.dropdown-toggle {
    font-size: 1rem;
    height: 16px;
  }
  /deep/.dropdown-item {
    display: grid;
    align-items: center;
    grid-template-columns: 16px 1fr;
    gap: 6px;
  }
}

















































































































































































































































































































































































































































































































































































































































































































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.styleguide-container > div {
  padding: 20px;

  h2 {
    font-size: 26px;
  }

  h3 {
    margin-top: 30px;
    font-size: 22px;
  }

  pre {
    background-color: #fff;
    padding: 10px;
  }
}
.theme-changer {
  position: fixed;
  padding: 5px !important;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  top: 125px;
  right: 25px;
  font-size: 10px;
  z-index: 2000;
  a {
    margin-left: 5px;
    &.active {
      background-color: #333;
      color: #fff;
      padding: 2px 5px;
      border-radius: 5px;
    }
  }
}

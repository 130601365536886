





































































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/elements/modal.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.big-button {
  border: none;
  background: none;
  font-size: 42px;
  margin-right: 20px;
  display: inline;
  .theme-employee & {
    color: $color-primary-darker-employee;
  }
  .theme-guardian & {
    color: $color-primary-darker-guardian;
  }
  .theme-child & {
    color: $color-primary-darker-child;
  }
  .theme-admin & {
    color: $color-primary-darker-admin;
  }
  div {
    padding-top: 15px;
  }
}

.button-text {
  font-size: 15px;
  width: 100%;
  .theme-employee & {
    color: $color-primary-darker-employee;
  }
  .theme-guardian & {
    color: $color-primary-darker-guardian;
  }
  .theme-child & {
    color: $color-primary-darker-child;
  }
  .theme-admin & {
    color: $color-primary-darker-admin;
  }
}

.center-buttons {
  margin-top: 3%;
}

.centerbox {
  margin-top: 140px;
}

.header-style {
  margin-top: 10%;
}

.container-scroll {
  overflow: auto;
}

.colored {
  color: $color_alert;
}

.link-style {
  color: $color-primary-dark;
  font-weight: bold;
}

.unmark-text {
  display: none;
  @include breakpoint-xs-up() {
    display: initial;
  }
}

.small-unmark-text {
  display: initial;
  @include breakpoint-sm-up() {
    display: none;
  }
}

.sletTextWidth {
  width: 50px !important;
}

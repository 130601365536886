





































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/presence/_presence.scss';
.editing-loading {
  &.aula-spinner {
    width: 70px;
    height: 70px;
    position: absolute;
    right: 22px;
    top: -12px;
  }
}
.new-pickup {
  padding: 15px 25px;
  @include breakpoint-lg-down() {
    padding-right: 40px;
  }
  .pickup-responsible-save {
    float: right;
    margin-bottom: -60px;
    padding: 14px 20px !important;
  }
  .new-pickup-responsible {
    border-radius: 0px !important;
    background-color: $color-grey-light !important;
    &.show-button {
      width: calc(100% - 85px);
    }
  }
}
.endDate {
  width: 100% !important;
}
.weekly-repeat {
  display: inline-flex;
  align-items: center;
  .help-tooltip {
    /deep/.questionmark-icon {
      border: 1px solid $color-grey-base !important;
    }
  }
}
.dropdown-select {
  --dropdown-max-width: auto;
  align-self: center;
}

.presence-repitition-helpbox {
  --questionmark-icon-background-color: var(--color-grey-light);
}












[data-sidebar='menu'] {
  display: flex;
  flex-direction: column;
  padding: 0;
}

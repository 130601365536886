



















.thread-compact-header {
  position: sticky;
  top: 0;
  z-index: var(--sticky-header-z-index);
  .recipient-header {
    padding-left: 52px;
  }
}

































































































































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.user {
  display: block;

  .icon {
    float: left;
  }

  .profile-picture-container {
    position: relative;
    width: fit-content;
    margin: auto;

    .profile-picture {
      --width: 150px;
      --height: 150px;
      --font-size: 45px;
    }

    .edit-avatar-button {
      position: absolute;
      right: 20px;
      top: 120px;
    }
  }

  .user-info {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    align-items: center;

    .user-fullname {
      font-size: 20px;
      font-weight: bold;
    }

    .user-institution {
      font-size: 16px;
    }

    .user-address {
      display: block;
      font-size: 15px;
      line-height: 24px;
    }
  }
  .user-update {
    display: block;
    width: 100%;
    padding-top: 40px;
  }
  .input-group-prepend {
    position: absolute;
    top: 13px;
    left: 20px;
    z-index: 10;
    gap: 4px;

    .icon-container {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      .icon-Aula_phone {
        font-size: 22px;
      }
      .email {
        font-size: 15px;
        padding-top: 4px;
      }
    }

    .contact-type {
      font-size: 16px;
      font-weight: 900;
    }
  }
  .input-group-append {
    position: absolute;
    top: 14px;
    right: 20px;
    z-index: 10;
    .pen {
      font-size: 20px;
      margin-top: 2px;
      color: var(--color-primary-darker);
    }
    .alert {
      position: relative;
      top: -12px;
      right: -40px;
    }
  }
  .onboarding-input {
    margin-bottom: 0;
    border-radius: 0;
    border-top: 1px solid $color-grey-base;
    padding-right: 50px;
    padding-left: 130px;
    font-size: 16px;
    &.last {
      border-bottom: 1px solid $color-grey-base;
    }
  }
}






















































































































































































































































































.tabs-buttons {
  --icon-size: 8px;
}

.print-button {
  --font-size: 16px;
}

.tab-item {
  text-transform: uppercase;
}

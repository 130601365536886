




















































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/core/animations.scss';

.header-dropdown {
  /deep/ .user-icon {
    background-color: $color-white !important;
    .shortname {
      .theme-admin & {
        color: $color-primary-darker-admin;
      }
      .theme-guardian & {
        color: $color-primary-darker-guardian;
      }
      .theme-employee & {
        color: $color-primary-darker-employee;
      }
      .theme-child & {
        color: $color-primary-darker-child;
      }
    }
  }
}

.menu-icon {
  /deep/ .user-icon {
    .early-student & {
      border: solid 3px $color-white;
    }
  }
}

.small-text {
  text-transform: uppercase;
  .early-student & {
    color: #fff !important;
    text-transform: capitalize;
    font-family: Baloo;
    font-weight: normal;
    font-size: 15px !important;
  }
}

.header-dropdown-menu {
  .aula-modal-open & {
    overflow: initial !important;
    z-index: 1 !important;
  }
}

@mixin default-thumbnail() {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: hidden;

  .thumbnail-circle {
    width: 100px;
    height: 100px;
    position: absolute;
    border-radius: 50%;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    margin: 0;
    border: solid 5px rgba(255, 255, 255, 0.5);
    background-color: var(--color-primary-base);

    i {
      position: absolute;
      display: block;
      top: calc(50% - 20px);
      font-size: 40px;
      color: $color-white;
      width: 100%;
    }
  }

  .thumbnail-label {
    position: absolute;
    bottom: -25px;
    font-size: 12px;
    font-weight: bold;
    left: calc(50% - 12px);
  }

  @include breakpoint-sm-up() {
    .thumbnail-circle {
      width: 130px;
      height: 130px;
      top: calc(50% - 65px);
      left: calc(50% - 65px);

      i {
        //left: calc(50% - 33px);
        top: calc(50% - 33px);
        font-size: 66px;
      }
    }
  }
}


@mixin mouse-hover {
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @content;
    }
  }
}

@mixin touch-hover {
  @media (hover: none) and (pointer: coarse) {
    &:hover {
      @content;
    }
  }
}














































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

#top-bar {
  color: $color-white;
  .back.btn {
    display: none;
  }
}

#aula-header /deep/ {
  .menu-icon {
    transition: transform 0.1s ease-in-out;
  }
  .header-dropdown:hover {
    .menu-icon {
      .early-student & {
        transform: scale(1.1);
      }
    }
  }
}

















































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.aula-posts-aside-container {
  position: relative;
  display: none;
  background-color: $color-grey-base;
  min-height: 100%;
  overflow: visible;
  box-shadow: var(--box-shadow-base);

  @include breakpoint-lg() {
    display: flex;
  }

  .daily-overview-container {
    width: 100%;
    flex: 0 0 auto;
  }

  .aula-posts-aside-overview {
    flex: 0 0 auto;
    position: relative;
    min-height: 100%;
    font-size: 18px;
    padding: 28px 25px 0 25px;
    background-color: $color-grey-base;
    width: 100%;
    margin-left: -100%;
    z-index: 10;

    /deep/ .quick-access-item {
      margin-top: 10px;
      height: 70px;
      line-height: 70px;
      padding: 0 25px;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      font-size: 15px;
      background: $color-white;
      &:hover {
        background: $color-grey-light;
      }
    }
  }

  .mask {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $color-grey-base;
    z-index: 10;
  }
}


































.photo-list-container {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  grid-auto-rows: 1fr;
  gap: 20px;
  break-inside: auto;
}

.photo-list-item {
  min-height: 200px;
  max-height: 250px;
}

.profile-frame-name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -moz-box-orient: vertical;
  -webkit-box-orient: vertical;
}

@media print {
  .photo-list-container {
    display: block;
    margin-left: -2mm;
    margin-right: -2mm;
  }

  .photo-list-item {
    min-height: unset;
    width: 19.8%;
    height: 61mm;
    display: inline-block;
    overflow: hidden;
    break-before: avoid;
    padding: 2mm;
    margin: 0;
  }
}

@media print and (orientation: landscape) {
  .photo-list-item {
    width: 16.5%;
    height: 52mm;
  }
}

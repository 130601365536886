






































































































































































































































































































































































































































































































































































@import '../shared/assets/scss/core/variables.scss';
@import '../shared/assets/scss/core/breakpoints.scss';

.impersonation-header {
  width: 50%;
  position: fixed;
  background: $color-error-text;
  height: var(--menu-top-bar-height);
  z-index: $impersonate-header-z-index;
  top: 0;
  left: 0;
  color: $color-white;
  padding: 15px;
  text-align: center;
  font-size: 16px;

  h1 {
    font-weight: normal;
    font-size: 20px;
  }

  h2 {
    font-size: 18px;
  }

  button {
    float: right;
  }
}

/* Top bar of Aula locked at top of screen when keyboard opens on ios */
.safari.keyboard .aula-content {
  bottom: 270px;
}

.content-wrapper {
  transition: margin-top 350ms;
}

.main-toastr-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 9999;

  @include breakpoint-sm-down() {
    width: auto;
    top: 20px;
    right: 20px;
    left: 20px;
  }
}



















































































































































































































































@import '../../../shared/assets/scss/core/breakpoints.scss';

.message-inboxes {
  [data-sidebar='content'] {
    @-moz-document url-prefix() {
      scrollbar-width: none;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
  height: auto;
  flex-shrink: 0;
  white-space: nowrap;
  margin: 4px 0 4px 4px;
  border-radius: 4px;
  min-width: 200px;
  max-width: 400px;

  @include breakpoint-lg-down() {
    min-width: 100%;
    max-width: unset;
  }

  @include breakpoint-lg-down() {
    width: 100%;
    margin: 0;
    padding-bottom: 120px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    gap: 4px;
  }

  .inbox-button {
    font-size: 14px;
    padding: 10px 8px;
    text-align: start;
    display: flex;
    gap: 8px;
    align-items: center;
    border-radius: 4px;
    border-right: 4px solid transparent;

    &:hover {
      background-color: var(--color-grey-base);
    }
    &[aria-selected] {
      background-color: var(--color-grey-light);
      font-weight: bold;
      border-right: 4px solid var(--color-primary-dark);
    }
    .folder-name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .sub-folders {
    padding-left: 40px;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  .dropdown-icon {
    --font-size: 0.4rem;

    vertical-align: middle;
  }
}

.separation-line {
  border-top: 1px solid var(--color-grey-base);
  margin-top: 20px;
  padding-top: 8px;
}
.folder-name-input {
  --modal-inner-input-background-color: var(--color-grey-light);

  margin-bottom: 12px;
  min-width: 400px;
  @include breakpoint-lg-down() {
    min-width: unset;
  }
}

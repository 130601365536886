














































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/login/onboarding.scss';
.privacy-policy-container {
  padding: 50px;
  @include breakpoint-sm-down() {
    padding: 10px;
  }
}
.aula-onboarding /deep/ a {
  font-weight: bold;
  color: $color-picker-button-blue;
  &:hover {
    text-decoration: underline;
  }
}





































































































.column-dropdown {
  max-width: 33.33336%;
  margin-top: 30px;
  margin-bottom: 25px;
}

.contact-name-cell {
  width: 320px;
  max-width: 320px;
  word-break: break-all;
}

@media print {
  .table {
    .head {
      .heading {
        font-size: 20px;
        padding: 10px;
      }
    }

    .body {
      .cell {
        padding: 10px;
        font-size: 17px;
      }
    }
  }
}

//Gallery Toolbar scss
.gallery-toolbar-container {
  position: relative;

  .gallery-toolbar {
    outline: none;
    font-size: 14px;
    background-color: $color-grey-base;
    padding-left: 0;
    height: 50px;

    @include breakpoint-sm-down() {
      background-color: transparent;
    }

    .navbar-nav {
      flex-direction: row;

      li {
        cursor: pointer;

        .in-circle.icon-Aula_plus {
          border-radius: 50%;
          color: $color-white;

          .theme-employee & {
            background-color: $color-primary-darker-employee;
          }

          .theme-guardian & {
            background-color: $color-primary-darker-guardian;
          }

          .theme-child & {
            background-color: $color-primary-darker-child;
          }

          .theme-admin & {
            background-color: $color-primary-darker-admin;
          }
        }
      }
    }
  }

  .hide-tablet-up {
    .icon-Aula_down-arrow {
      font-size: 5px;
      position: relative;
      top: -1px;
      margin-left: 4px;
    }
  }

  .mobile-gallery-toolbar {
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100vh;
    min-width: 100vw;
    z-index: 1030;
    background-color: $color-grey-base;
    font-size: 14px;
    font-weight: bold;
    color: $color-primary-darker;

    .menu-header {
      position: relative;
      width: 100%;
      height: 57px;
      top: 0;
      padding: 0;
      background-color: $color-primary-darker;
      font-weight: bold;

      .icon-Aula_arrow_new_item {
        color: $color-white;
        font-weight: 900;
        font-size: 28px;
        display: inline-block;
        line-height: 57px;
        vertical-align: top;
        float: left;
        margin: 0 -48px 0 20px;
        -webkit-transform: rotate(270deg);
        -moz-transform: rotate(270deg);
        -o-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
        transform: rotate(270deg);
      }

      .text {
        color: $color-white;
        line-height: 57px;
        text-align: center;
        font-size: 14px;
      }
    }

    .menu-body {
      .toolbar-title {
        height: 45px;
        width: 100%;
        background-color: $color-grey-base;
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 29px;
        border-bottom: 1px solid $color-white;
      }

      .toolbar-item {
        height: 45px;
        width: 100%;
        background-color: $color-grey-lighter;
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 49px;
        margin-bottom: 0;
        border-bottom: 1px solid $color-white;
      }
    }
  }
}

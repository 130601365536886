























































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

#message-autoreply {
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  margin: 0 auto;

  @include breakpoint-lg() {
    padding: 50px 50px 0;
  }

  @include breakpoint-lg-down() {
    position: absolute;
    padding: 20px;
    left: 0;
    right: 0;
    top: 0;
  }

  /deep/ .el-date-editor {
    width: auto;
  }
}

#message-textarea {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding: 15px 15px;
  border: none;

  &:focus-visible {
    box-shadow: inherit;
    border: inherit;
  }
}

.autoreply-title {
  font-size: 18px;
  font-weight: bold;
}

.btn-link {
  cursor: pointer;
  padding: 14px 44px;
}

label {
  display: block;
}

.el-input,
.timepicker-container {
  width: 100%;
}

.autoreply-scrollbar {
  height: 100%;
  overflow-y: auto;
  padding: 5px;
}






































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.album-description {
  height: 50px;
  @include breakpoint-sm-down() {
    height: unset;
  }
}

.thumb {
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.aula-circle {
  margin: auto;

  &.disabled {
    opacity: 0.4;
  }
}

.icon-Album {
  font-size: 26px;
  margin-right: 10px;
}

.aula-spinner {
  width: 80px;
  height: 80px;
  position: absolute;
  right: 30px;
  top: -12px;

  &.mobile {
    right: -10px;
  }
}

.media-upload-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  @include breakpoint-lg() {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.media-upload-preview {
  padding-bottom: 110px;
}

.scroller.direction-vertical {
  overflow: visible !important;

  & > /deep/ .vue-recycle-scroller__item-wrapper {
    overflow: visible !important;

    & > .vue-recycle-scroller__item-view {
      &:is(:has(ul.dropdown-menu.show), :focus-within) {
        z-index: 1;
      }
    }
  }
}

.aula-circle-small {
  display: flex;
}


































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.app-settings {
  max-width: 1024px;
  .description {
    font-size: 18px;
  }
  h2 {
    margin-top: 40px;
  }
  .remove {
    position: relative;
    top: 18px;
  }
}








































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/elements/modal.scss';

.colored {
  color: var(--color-alert);
}

.mobile-action-buttons-container {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  padding-right: 8px;
  flex-flow: nowrap;
  height: 60px;
  width: 100%;

  @include breakpoint-lg() {
    display: none;
  }

  .back-button {
    margin-right: auto;
    margin-left: 12px;
    padding: 8px;
    transform: rotate(270deg);

    .aula-icon {
      --font-size: 24px;
      font-weight: bold;
    }
  }

  .quick-action-button {
    color: var(--color-white);
    height: fit-content;
  }

  .buttons {
    flex-wrap: nowrap;
    gap: 8px;
    height: fit-content;
  }

  .icon-container {
    background-color: transparent;

    .aula-icon {
      --font-size: 24px;
    }
  }
}

.more-actions-dropdown {
  /deep/ .dropdown-toggle.dropdown-toggle-no-caret {
    color: var(--color-white);
    --font-size: 24px;
    height: 100%;
  }

  /deep/ &.show .dropdown-toggle.dropdown-toggle-no-caret {
    color: initial;
    z-index: 1001;
  }

  /deep/ &.show .dropdown-menu {
    max-height: calc(100vh - 90px);
    overflow-y: auto;

    .dropdown-item {
      display: flex;
      flex-direction: row;
      font-size: 14px;

      .icon-container {
        background-color: transparent;

        .aula-icon {
          --font-size: 20px;
        }
      }

      span {
        padding-left: 8px;
        align-self: center;
      }
    }
  }
}

/deep/ .dropdown-menu.action-menu {
  margin-top: -40px;
  padding-top: 48px;
  padding-bottom: 24px;
}

/deep/ .toggle-button {
  display: flex;
  align-items: center;
  text-decoration: none;
}












































































































.thread-compact-item {
  &:hover .thread-checkbox {
    visibility: visible;
  }
  .bundle-icon {
    font-size: 0.5rem;
    visibility: hidden;
    vertical-align: middle;
    transform: rotate(0);
    transition: transform 200ms linear;

    .bundle & {
      visibility: visible;
    }
    .bundle-open & {
      transform: rotate(180deg);
    }
  }
  .recipient-text {
    font-size: 0.9rem;
  }
  .recipient-name {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .draft-badge {
    font-size: 0.6rem;
    align-self: center;
    display: none;
    line-height: 12px;
    .has-draft & {
      display: initial;
    }
  }
  .thread-checkbox {
    visibility: hidden;
    .selected &,
    .multiple-selected &{
      visibility: visible;
    }
  }
  .sensitive-icon {
    display: none;
    font-size: 0.9rem;
    color: var(--color-alert);
    .sensitive & {
      display: initial;
    }
  }
}

.bundle-container {
  .recipient-name {
    padding-left: 1rem;
  }
}

.thread-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  i.icon-Aula_star_solid {
    color: var(--color-alert);
    font-size: 0.6rem;
    margin-top: 3px;
  }
  .last-message-sent-time {
    font-size: 0.9rem;
  }
}
.thread-actions {
  display: none;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
}
.unread .thread-compact-item {
  font-weight: bold;
}

.unread .thread-compact-item,
.active-thread .thread-compact-item {
  --thread-background-color: var(--color-grey-base);
}

.selected .selectable {
  --thread-background-color: var(--color-grey-base);
}
.hoverable:hover {
  --thread-background-color: var(--color-grey-dark);
  .thread-date {
    display: none;
  }
  .thread-actions {
    display: flex;
  }
}

.mute-icon {
  display: none;
  .subscription.muted & {
    display: initial;
  }
}

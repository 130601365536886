



































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.header-dropdown-menu-search {
  width: 243px;
  top: 0 !important;
  .search-filter {
    margin: 22px 0 13px;
  }
  .search-filter-container {
    padding: 10px;
  }
}

.search-result-text-col {
  @include breakpoint-lg() {
    width: auto;
  }
}

.search-result-right-col-text {
  @include breakpoint-lg() {
    padding: 52px 25px 0;
    font-size: 15px;
  }
}

.search-result-group-button {
  font-size: small;
  @include breakpoint-lg() {
    font-size: 15px;
  }
}

.search-filter-results {
  justify-content: space-between;
  margin-left: unset;
  margin-right: unset;
}


















































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/core/animations.scss';

.global-filter {
  --aula-badge-box-shadow-color: var(--color-primary-dark);
  &.active-filter {
    --aula-badge-box-shadow-color: var(--color-primary-darker);
  }
}

.filter-list {
  border-right: 1px solid $color-white;
  .badge-notification {
    position: absolute;
    top: 5px;
    left: 6px;
    background-color: var(--color-white);
    color: var(--color-primary-darker);
  }
  .profile-filter /deep/.custom-control-label::before {
    border: 1px solid var(--color-primary-base) !important;
    background-color: transparent;
  }
  /deep/.custom-control-label::before {
    border: 1px solid var(--color-white) !important;
  }
  /deep/.custom-control-input:checked ~ .custom-control-label::before {
    background-color: var(--color-primary-dark);
  }

  :not(.slider) > .global-filter /deep/ .filter-checkbox {
    .custom-control-label:before {
      width: 21px;
      height: 21px;
      border: 1px solid $color-white !important;
    }
    .custom-control-label:after {
      font-size: 21px;
      left: -28px;
      top: 0px;
    }
  }

  .slider > .global-filter /deep/ .filter-checkbox {
    input[type='checkbox'].custom-control-input {
      display: none;
    }
  }

  .filter-checkbox {
    box-shadow: none !important;
  }

  .filter-text {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: var(--color-primary-darker);
    width: 100%;

    &.top-header-button {
      top: 87px !important;
    }

    &.small-text {
      color: $color-darkblue;
    }

    &:not(.small-text) {
      top: inherit;
      bottom: 15px;
      @include breakpoint-lg-down() {
        bottom: 2px;
      }
    }
  }

  .filter-dropdown {
    > .global-filter {
      float: right;
    }
  }
}

.close {
  position: absolute;
  text-transform: uppercase;
  font-size: 12px;
  right: 0;
  height: 100%;

  div {
    position: relative;
    top: 20px;
    right: 25px;
  }

  i {
    font-size: 46px;
  }
}

@include breakpoint-lg() {
  .slider {
    float: right;
    margin-right: 80px;
  }
}

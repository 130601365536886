





































































































































































































































































































































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/messages/_messagetype.scss';

.conversation {
  .message-type-container {
    position: relative;
  }
  .message-info {
    font-size: 15px;
    padding: 0 10%;
    @include breakpoint-lg-down() {
      padding: 0 24px;
    }
  }

  .more-unread-messages {
    font-size: 13px;
    position: fixed;
    top: 355px;
    padding: 15px;
    background-color: $color-grey-light;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(193, 192, 192, 0.5);
    z-index: 1;
    cursor: pointer;
  }

  .more-unread-messages-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .conversation-content-with-unread {
    position: relative;
    @include breakpoint-tablet-landscape() {
      padding-bottom: 0 !important;
    }
    @include breakpoint-tablet-portrait() {
      padding-bottom: 0 !important;
    }
  }

  .messagesSelectable /deep/ .row {
    cursor: pointer;
  }

  .message-multiselect {
    position: sticky;
    top: 57px;
    padding: 20px;
    background-color: var(--color-grey-light);
    z-index: 2;

    .actions {
      display: flex;
      gap: 12px;
      justify-content: flex-end;
    }
  }

  .unreadFromHere .col-8 {
    span {
      margin-left: 20px;
      background: $color-grey-light;
      color: $color-help-text;
      display: inline-block;
      padding: 4px;
      position: relative;
      z-index: 2;
      font-size: 12px;
    }
    &:after {
      display: block;
      height: 2px;
      position: relative;
      z-index: 1;
      top: -13px;
      content: '';
      border-top: 1px solid $color-help-text;
    }
  }

  .btn-link {
    cursor: pointer;
  }

  .left {
    left: 0;
  }

  .right {
    right: 0;
  }
}

article {
  &.message {
    padding: 2px 0px;
  }
  @include breakpoint-xs-up() {
    &.message {
      padding: 18px 0px;

      &.message-from-me {
        width: 90%;
        margin-left: 10%;
      }

      &.message-from-person {
        max-width: 90%;
      }
    }
  }

  @include breakpoint-sm-up() {
    &.message {
      &.message-from-me,
      &.message-from-person {
        max-width: 80%;
      }

      &.message-from-me {
        margin-left: 20%;
      }
    }
  }
}
